@use '@angular/material' as mat;
@use 'sass:map';

@mixin initials-circle-theme($config-or-theme) {
  $warn: map.get($config-or-theme, warn);
  $accent: map.get($config-or-theme, accent);
  $primary: map.get($config-or-theme, primary);

  .initials-circle {
    background: mat.m2-get-color-from-palette($primary);
    color: mat.m2-get-color-from-palette($primary, default-contrast);
  }

  .initials-circle.accent {
    background: mat.m2-get-color-from-palette($accent);
    color: mat.m2-get-color-from-palette($primary, default-contrast);
  }

  .initials-circle.warn {
    background: mat.m2-get-color-from-palette($warn);
    color: mat.m2-get-color-from-palette($primary, default-contrast);
  }
}
