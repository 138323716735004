@use '@angular/material' as mat;
@use 'sass:map';
@use 'tremaze-components-themes' as tremaze-components;

@import '../styles/base';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@mixin theme-base-mixin(
  $primary,
  $accent,
  $warn,
  $typography,
  $fontFaceURL,
  $material-theme-overrides: null
) {
  @if ($fontFaceURL != null) {
    @import url($fontFaceURL);
  }
  @include mat.core();
  html {
    font-family: mat.m2-font-family($typography, 'body-1');
  }

  // Define the palettes for your theme using the Material Design palettes available in palette.scss
  // (imported above). For each palette, you can optionally specify a default, lighter, and darker
  // hue. Available color palettes: https://material.io/design/color/
  $tremaze-primary: mat.m2-define-palette($primary);
  $tremaze-accent: mat.m2-define-palette($accent);

  // The warn palette is optional (defaults to red).
  $tremaze-warn: mat.m2-define-palette($warn);

  $tremaze-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $tremaze-primary,
        accent: $tremaze-accent,
        warn: $tremaze-warn,
      ),
      typography: $typography,
    )
  );

  @if ($material-theme-overrides != null) {
    $tremaze-theme: map.deep-merge($tremaze-theme, $material-theme-overrides);
  }

  $tremaze-dark-theme: mat.m2-define-dark-theme(
    (
      color: (
        primary: $tremaze-primary,
        accent: $tremaze-accent,
        warn: $tremaze-warn,
      ),
      density: 0,
      typography: $typography,
    )
  );

  @include include-all-styles($tremaze-theme, $tremaze-dark-theme);
  @include tremaze-components.include-tremaze-component-themes(
    $tremaze-theme,
    $tremaze-dark-theme
  );

  // override mat-card box-shadow
}
