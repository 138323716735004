@use '@angular/material' as mat;
@use 'sass:map';
@import 'libs/shared/ui/initials-circle/src/lib/initials-circle.theme.ts';

@mixin page-mixin($theme) {
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $type: mat.get-theme-type($theme);

  .page__head {
    background: mat.m2-get-color-from-palette($primary);
  }
  .page__actions {
    background: mat.m2-get-color-from-palette($background, card);
  }
  .primary-hover {
    &:hover {
      background-color: mat.m2-get-color-from-palette($primary, 0.1) !important;
    }
  }
  .primary-bg {
    background-color: mat.m2-get-color-from-palette($primary);
  }
  .primary-bg-light {
    background-color: mat.m2-get-color-from-palette($primary, 0.1);
  }
  .accent-bg {
    background-color: mat.m2-get-color-from-palette($accent);
  }
  .accent-bg-light {
    background-color: mat.m2-get-color-from-palette($accent, 0.1);
  }
  .primary-fg {
    color: mat.m2-get-color-from-palette($primary, default-contrast);
  }
  .primary-color {
    color: mat.m2-get-color-from-palette($primary) !important;
  }
  .accent-fg {
    color: mat.m2-get-color-from-palette($accent, default-contrast);
  }
  .accent-color {
    color: mat.m2-get-color-from-palette($accent) !important;
  }
  .light-hint-text {
    color: mat.m2-get-color-from-palette($foreground, hint-text);
  }

  .lighter-text {
    color: if($type == dark, #a8a8a8, #4b4b4b);
  }

  .highlighted-textbox {
    color: if($type == dark, #c4c4c4, #4b4b4b);
    background-color: if($type == dark, #4b4b4b, #f5f5f5);
    border-color: if($type == dark, #3a3a3a, #e5e5e5);
    border-width: 1px;
    border-style: solid;
    padding: 10px;
    border-radius: 10px;

    > *:first-child {
      margin: 0;
    }
  }
}

@mixin includeTheme($light, $dark) {
  $color-config: mat.m2-get-color-config($light);
  $primary-palette: map.get($color-config, 'primary');
  $accent-palette: map.get($color-config, 'accent');
  @include mat.core-theme($light);
  @include mat.all-component-themes($light);
  @include event-week-schedule-item-mixin($light);
  @include login-page-mixin(mat.m2-get-color-from-palette($primary-palette));
  @include page-mixin($light);
  @include initials-circle-theme($light);
  @include blockquote-theme($light);

  .cdk-drag-preview {
    background: white;
  }

  .dark-theme {
    @include mat.all-component-colors($dark);
    @include event-week-schedule-item-mixin($dark);
    @include login-page-mixin(white);
    @include page-mixin($dark);
    @include initials-circle-theme($dark);
    @include blockquote-theme($dark);

    //$config: mat-get-color-config($dark);
    //$background: map-get($config, background);

    .cdk-drag-preview {
      background: mat.m2-get-color-from-palette(map-get($dark, background), card);
      color: white;
    }

    $background: map.get($dark, background);

    .mat-toolbar {
      background: mat.m2-get-color-from-palette($background, app-bar) !important;
      color: white;
    }
  }
}
