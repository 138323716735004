@use 'sass:map';
@use '@angular/material' as mat;
@import 'globals';

@mixin palette($theme, $paletteName) {
  $border-color: mat.get-theme-color($theme, foreground, divider);
  $background-color: mat.get-theme-color($theme, background, card);
  $text-color: mat.get-theme-color($theme, foreground, text);
  $icon-color: mat.get-theme-color($theme, $paletteName, default);

  $hover-background-color: mat.get-theme-color($theme, $paletteName, 100);
  $hover-text-color: mat.get-theme-color($theme, $paletteName, 100-contrast);
  $hover-icon-color: $hover-text-color;

  $active-background-color: mat.get-theme-color($theme, $paletteName, default);
  $active-text-color: mat.get-theme-color(
    $theme,
    $paletteName,
    default-contrast
  );
  $active-icon-color: $active-text-color;

  $disabled-background-color: mat.get-theme-color(
    $theme,
    background,
    disabled-button
  );
  $disabled-text-color: mat.get-theme-color(
    $theme,
    foreground,
    disabled-button
  );
  $disabled-icon-color: $disabled-text-color;

  .icon-text-button {
    transition:
      background-color 0.2s,
      color 0.2s;

    &--#{$paletteName} {
      border-color: $border-color;
      background-color: $background-color;
      color: $text-color;

      .icon-text-button__Icon {
        color: $icon-color;
      }

      &.icon-text-button--active:not(&.icon-text-button--disabled) {
        background-color: $active-background-color;
        color: $active-text-color;

        .icon-text-button__Icon {
          color: $active-icon-color;
        }
      }

      &.icon-text-button--disabled {
        background-color: $disabled-background-color;
        color: $disabled-text-color;

        .icon-text-button__Icon {
          color: $disabled-icon-color;
        }
      }

      &:hover:not(&.icon-text-button--active):not(
          &.icon-text-button--disabled
        ) {
        background-color: $hover-background-color;
        color: $hover-text-color;

        .icon-text-button__Icon {
          color: $hover-icon-color;
        }
      }
    }
  }
}

@mixin color($theme) {
  @include palette($theme, primary);
  @include palette($theme, accent);
  @include palette($theme, warn);
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
