@use 'sass:map';
@use '../../../../../../node_modules/@angular/material/index' as mat;
@import 'globals';

@mixin color($theme) {
  $type: mat.get-theme-type($theme);
  $app-background-color: if($type == dark, #333, #fff);
  $background-color: if($type == dark, #555555, #f0f0f0);
  $color: if(
    $type == dark,
    white,
    mat.get-theme-color($theme, primary, 50-contrast)
  );

  .tremaze-chip {
    background-color: $background-color;
    color: $color;
  }

  .tremaze-expandable-chip-collection {
    background-color: $background-color;

    .tremaze-chip {
      background-color: $app-background-color;
      color: $color;
    }

    &__Hidden-Chips-Preview {
      background-color: mat.get-theme-color($theme, primary, default);
      color: mat.get-theme-color($theme, primary, default-contrast);
    }
  }
}

@mixin theme($theme) {
  @if mat.theme-has($theme, color) {
    @include color($theme);
  }
}
