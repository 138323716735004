@import url("https://assets.tremaze.de/fonts/Roboto/fontface.css");
.tremaze-card {
  overflow: hidden;
}

.tremaze-card__Header__Text {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 0;
}

.tremaze-card__Title {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.tremaze-card__Content {
  display: block;
}

.tremaze-card__Avatar {
  margin-right: 10px;
}

.tremaze-card__Footer {
  display: flex;
}

.tremaze-card__Footer--align-start {
  justify-content: flex-start;
}

.tremaze-card__Footer--align-end {
  justify-content: flex-end;
}

.tremaze-card__Footer--align-center {
  justify-content: center;
}

.tremaze-card__Footer--align-stretch {
  justify-content: stretch;
}

.tremaze-chip {
  border-radius: 2.5rem;
  display: inline-flex;
  align-items: center;
  text-align: center;
  padding: 0.25em 1em;
  height: 2.5rem;
  box-sizing: border-box;
  font-size: 0.875em;
  font-weight: 500;
  line-height: 1;
  transition: background-color 0.3s;
  min-width: 100px;
  text-decoration: none;
  overflow: hidden;
}

.tremaze-chip .tremaze-avatar {
  height: calc(2.5rem - 2 * 0.25em);
  width: calc(2.5rem - 2 * 0.25em);
  font-size: calc(calc(2.5rem - 2 * 0.25em) * 0.4) !important;
  margin-left: calc(-0.75em) !important;
  margin-right: 0.3rem;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.tremaze-chip .tremaze-avatar * {
  height: inherit !important;
  width: inherit !important;
  font-size: inherit !important;
}

.tremaze-expandable-chip-collection {
  font-size: 1rem;
  display: flex;
  padding: 0.25em;
  border-radius: 1.8em;
  max-width: 100%;
  align-items: flex-start;
  overflow: hidden;
}

.tremaze-expandable-chip-collection--expanded .tremaze-expandable-chip-collection__Container {
  flex-wrap: wrap;
  height: auto;
}

.tremaze-expandable-chip-collection--expanded .tremaze-expandable-chip-collection__Expand-Button {
  transform: rotate(90deg);
}

.tremaze-expandable-chip-collection:not(.ng-animating) .tremaze-expandable-chip-collection__Expand-Button--hidden {
  opacity: 0;
  tab-index: -1;
}

.tremaze-expandable-chip-collection__Hidden-Chips-Preview {
  font-size: 1.1em;
  border-radius: 50%;
  font-weight: 600;
  height: calc((2.5rem - 2 * 0.25em) * 0.9);
  width: calc((2.5rem - 2 * 0.25em) * 0.9);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.tremaze-expandable-chip-collection__Expand-Button {
  transition: transform 0.3s ease;
  align-self: start;
  height: 2.5rem !important;
  width: 2.5rem !important;
  font-size: 1.2em;
  border: none;
}

.tremaze-expandable-chip-collection__Container {
  flex: 1;
  overflow: hidden;
  gap: 0.45em;
  display: flex;
  height: 2.5rem;
}

.tremaze-expandable-chip-collection .tremaze-chip {
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 0;
  transition: opacity 300ms ease;
  transition-delay: 300ms;
}

.icon-text-button {
  border-radius: 20px;
  border-width: 1.5px;
  box-shadow: none;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  padding: 15px;
  text-align: left;
  cursor: pointer;
  justify-content: center;
}

.icon-text-button--has-text {
  flex-direction: column;
  gap: 0;
  padding: 20px 25px;
  justify-content: normal;
}

.icon-text-button--has-text .icon-text-button__Icon {
  margin-bottom: 20px;
}

.icon-text-button--has-text .icon-text-button__Title {
  margin-bottom: 10px;
}

.icon-text-button__Icon {
  font-size: 24px;
}

.icon-text-button__Title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 20px;
}

.icon-text-button__Text {
  margin: 0;
  font-size: 18px;
}

.icon-text-button-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

@media (max-width: 600px) {
  .icon-text-button-group {
    grid-template-columns: 1fr;
  }
}
.center-children-vertically, .center-children {
  display: flex;
  align-items: center;
}

.center-children-horizontally, .center-children {
  display: flex;
  justify-content: center;
}

.center-text {
  text-align: center;
}

.d-flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

mat-accordion mat-expansion-panel mat-expansion-panel-header {
  height: 40px;
}
mat-accordion mat-expansion-panel .mat-expansion-panel-content {
  height: 0;
}
mat-accordion mat-expansion-panel.mat-expanded mat-expansion-panel-header {
  height: 64px;
}
mat-accordion mat-expansion-panel.mat-expanded .mat-expansion-panel-content {
  height: auto;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
}

.spacer {
  flex: 1 1 0;
}

.d-none {
  display: none !important;
}

.green-color {
  color: green;
}

.red-color {
  color: red;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
             supported by Chrome, Edge, Opera and Firefox */
}

.custom-scrollbar, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container, .mat-dialog-content, .table-wrapper__container__table-wrapper, .page__body, .page__Body, html,
body {
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8;
}
.custom-scrollbar::-webkit-scrollbar, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container::-webkit-scrollbar, .mat-dialog-content::-webkit-scrollbar, .table-wrapper__container__table-wrapper::-webkit-scrollbar, .page__body::-webkit-scrollbar, .page__Body::-webkit-scrollbar, html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: 0 0;
  padding-right: 3px;
  padding-bottom: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container::-webkit-scrollbar-thumb, .mat-dialog-content::-webkit-scrollbar-thumb, .table-wrapper__container__table-wrapper::-webkit-scrollbar-thumb, .page__body::-webkit-scrollbar-thumb, .page__Body::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 2px;
  margin-right: 3px;
  margin-bottom: 3px;
}

blockquote {
  border-left: 3px solid;
  margin-left: 10px;
  padding: 10px 0 10px 10px;
  border-color: lightgray;
}
blockquote > *:first-child {
  margin: 0;
}

/*------------------------------------*\
    #PAGE
\*------------------------------------*/
html,
body {
  height: 100%;
}

body {
  margin: 0;
}

.cdk-drag-handle:not(.disabled) {
  cursor: grab;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .cdk-drag:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  opacity: 0;
}

hr {
  margin: 0;
}

::ng-deep .ng-animating div mat-accordion mat-expansion-panel mat-expansion-panel-header {
  height: 48px;
}

::ng-deep .ng-animating div mat-accordion mat-expansion-panel div.mat-expansion-panel-content {
  height: 0px;
  visibility: hidden;
}

.fade-animation-outlet-wrapper {
  position: relative;
  flex: 1;
  overflow: hidden !important;
}
.fade-animation-outlet-wrapper > router-outlet ~ * {
  position: absolute;
  width: 100%;
  height: 100%;
}
.fade-animation-outlet-wrapper > router-outlet ~ * > * {
  height: 100%;
}

table {
  width: 100%;
}

.page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
}
.page__head, .page__Head {
  padding: 20px 20px;
  z-index: 2;
  display: flex;
  align-items: center;
}
.page__head:not(.page__head--flat, .page__Head--flat), .page__Head:not(.page__head--flat, .page__Head--flat) {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.page__head--title-center, .page__Head--title-center {
  justify-content: center;
  text-align: center;
}
.page__title, .page__Title {
  margin: 0;
}
.page__body, .page__Body {
  flex: 1;
  overflow: auto;
}
.page__body--no-scroll, .page__Body--no-scroll {
  overflow: hidden;
}
.page__body__empty, .page__Body__empty {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.page__body__empty mat-icon, .page__Body__empty mat-icon {
  font-size: 35px;
}
.page__body__empty *, .page__Body__empty * {
  text-align: center;
}
.page__actions, .page__Actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 20px;
  border-top: 1px solid rgba(211, 211, 211, 0.25);
}
.page__actions > *:not(:last-child), .page__Actions > *:not(:last-child) {
  margin-right: 10px;
}

.center-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

tremaze-main tremaze-main-nav-header ~ mat-nav-list .mdc-list-item-content {
  padding: 0 !important;
}
tremaze-main tremaze-main-nav-header ~ mat-nav-list .mdc-list-item-content > a {
  padding: 0 16px;
}

.form {
  display: flex;
  flex-direction: column;
}
.form__row {
  display: flex;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
}
.form__row .mat-form-field {
  min-width: 250px;
  width: 100%;
  max-width: 500px;
}
.form__col {
  max-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.form__col:not(:last-child) {
  margin-right: 10px;
}

.form-grid-wrapper {
  display: grid;
  grid-gap: 1rem;
}

.form-grid-container {
  display: flex;
  flex-wrap: wrap;
  column-gap: 1rem;
  row-gap: 2rem;
  align-items: flex-start;
  padding: 1rem 0;
  width: min(75rem, 100%);
}
.form-grid-container--Max-Cols-2 .form-grid {
  grid-template-columns: repeat(auto-fill, minmax(max(40%, 18.75rem), 1fr)) !important;
}
@media screen and (max-width: 400px) {
  .form-grid-container--Max-Cols-2 .form-grid {
    grid-template-columns: repeat(auto-fill, minmax(max(20%, 12.5rem), 1fr)) !important;
  }
}
.form-grid-container--Max-Cols-3 .form-grid {
  grid-template-columns: repeat(auto-fill, minmax(max(30%, 18.75rem), 1fr)) !important;
}
@media screen and (max-width: 400px) {
  .form-grid-container--Max-Cols-3 .form-grid {
    grid-template-columns: repeat(auto-fill, minmax(max(20%, 12.5rem), 1fr)) !important;
  }
}
.form-grid-container--Max-Cols-1 .form-grid {
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
  min-width: 100%;
}
.form-grid-container > * {
  min-width: min(25rem, 100%);
  max-width: 100%;
  flex: 1;
}
.form-grid-container > .form-grid-container {
  min-width: 100%;
}

.form-grid-item-new-row {
  grid-column-start: 1;
}
.form-grid-item-new-row.form-grid-item-2-col {
  grid-column-end: 3;
}
.form-grid-item-new-row.form-grid-item-3-col {
  grid-column-end: 4;
}

.form-grid-item-2-col {
  grid-column: span 2;
}

.form-grid-item-3-col {
  grid-column: span 3;
}

.form-grid-item-full-width, .form-grid > h1,
.form-grid > h2,
.form-grid > h3,
.form-grid > h4,
.form-grid > h5,
.form-grid > h6,
.form-grid fieldset > h1,
.form-grid fieldset > h2,
.form-grid fieldset > h3,
.form-grid fieldset > h4,
.form-grid fieldset > h5,
.form-grid fieldset > h6 {
  grid-column: 1/-1;
}

mat-tab-body > :first-child > fieldset:first-child {
  margin-top: 15px;
}

.dark-theme fieldset legend {
  color: rgb(211, 211, 211);
}

fieldset {
  margin-inline-start: 0;
  margin-inline-end: 0;
  border-color: rgba(128, 128, 128, 0.2);
  border-radius: 10px;
  background-color: rgba(128, 128, 128, 0.04);
  padding-bottom: 20px;
}
fieldset legend {
  color: #4c4c4c;
}

.form-grid,
.form-grid fieldset {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(18.75rem, 100%), 1fr));
  grid-gap: 1rem;
  align-items: flex-start;
  width: 100%;
  box-sizing: border-box;
  max-width: 100%;
}
.form-grid--dense,
.form-grid fieldset--dense {
  grid-gap: 0.333rem;
}
.form-grid .mat-form-field-appearance-outline .mat-form-field-wrapper,
.form-grid fieldset .mat-form-field-appearance-outline .mat-form-field-wrapper {
  margin: 0;
}
.form-grid > h1,
.form-grid > h2,
.form-grid > h3,
.form-grid > h4,
.form-grid > h5,
.form-grid > h6,
.form-grid fieldset > h1,
.form-grid fieldset > h2,
.form-grid fieldset > h3,
.form-grid fieldset > h4,
.form-grid fieldset > h5,
.form-grid fieldset > h6 {
  margin: 0;
}
.form-grid--force-vertical,
.form-grid fieldset--force-vertical {
  grid-template-columns: 1fr;
}
.form-grid--Center-Items,
.form-grid fieldset--Center-Items {
  place-items: start center;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

table:not(.default-table) tbody tr:hover {
  background-color: rgba(136, 136, 136, 0.1) !important;
}

.dark-theme .default-table thead {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1411764706);
}

.default-table {
  border-collapse: collapse;
}
.default-table thead th {
  font-size: 1.15rem;
  font-weight: bold;
}
.default-table td {
  padding: 12px 5px;
  vertical-align: top;
}
.default-table th {
  text-align: left;
}
.default-table tr {
  transition: background-color 0.2s ease-in-out;
}

.table-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.table-wrapper__container {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-top: 15px;
}
.table-wrapper__container__table-wrapper {
  overflow: auto;
  flex: 1;
}
.table-wrapper__container__table-wrapper table {
  margin-bottom: 30px;
}
.table-wrapper__container__table-wrapper__reload {
  margin-left: auto;
}
.table-wrapper__container__filter {
  display: flex;
  padding: 10px 20px 0 10px;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.table-wrapper__container__filter__reset {
  margin-right: 20px;
  cursor: pointer;
}
.table-wrapper__container__filter__reset:hover, .table-wrapper__container__filter__reset:focus {
  text-decoration: underline;
}

.dark-theme a {
  color: #3ea1dc;
}
.dark-theme a:visited {
  color: #a05dde;
}

button.custom-button {
  border: none;
  background: none;
  padding: 0;
  color: rgba(0, 0, 0, 0.5294117647);
  cursor: pointer;
}
button.custom-button:not(:first-child) {
  margin-left: 8px;
}

.dark-theme button.custom-button {
  color: rgba(255, 255, 255, 0.6588235294);
}

.dark-theme input[type=time]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.mat-input-element[type=date]::after,
.mat-input-element[type=datetime]::after,
.mat-input-element[type=datetime-local]::after,
.mat-input-element[type=month]::after,
.mat-input-element[type=week]::after,
.mat-input-element[type=time]::after {
  content: none !important;
}

blockquote {
  border-left: 3px solid;
  margin-left: 10px;
  padding: 10px 0 10px 10px;
  border-color: lightgray;
}
blockquote > *:first-child {
  margin: 0;
}

.innerHTML ul {
  list-style-type: revert;
  padding: revert;
  margin: revert;
}

gridster {
  background: transparent !important;
}
gridster gridster-item {
  background: transparent !important;
  margin: 10px;
  border-radius: 5px;
}
* > :last-child gridster gridster-item > :first-child {
  height: 100%;
}
gridster gridster-item .mat-card-header {
  display: flex;
  justify-content: space-between;
}
gridster gridster-item mat-card {
  box-shadow: none !important;
  position: static !important;
}
gridster gridster-item .table-wrapper__container,
gridster gridster-item .table-wrapper__container > mat-card,
gridster gridster-item .table-wrapper__container__filter {
  padding-top: 0 !important;
}
gridster gridster-item .table-wrapper__container > mat-card {
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 0 !important;
}

.grid-item-content > *:not(.grid-item-content__expand-button) {
  height: 100%;
}

.mdc-icon-button.small-icon-button {
  height: 1.5rem !important;
  width: 1.5rem !important;
  font-size: 1.1rem !important;
  padding: 0.4rem !important;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.mdc-icon-button.small-icon-button .mat-mdc-button-touch-target {
  height: inherit !important;
  width: inherit !important;
}

.mat-mdc-table .mat-column-actions {
  width: 250px;
  overflow: auto;
  white-space: nowrap;
}
.mat-mdc-table.dense-actions .mat-column-actions {
  max-width: 120px;
  width: 120px;
}
.mat-mdc-table .mat-cell:not(:first-child) {
  padding-left: 5px;
}
.mat-mdc-table .mat-cell:not(:last-child) {
  padding-right: 5px;
}
.mat-mdc-table .mat-header-cell {
  padding-left: 7px;
}

mat-card {
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
  border-radius: 25px !important;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 25px !important;
}
mat-card .mat-mdc-card-header {
  padding-bottom: 8px !important;
}
mat-card mat-card-content {
  flex: 1 !important;
  overflow: hidden;
}
mat-card.enableScroll mat-card-content {
  overflow: auto;
}

.custom-scrollbar, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container, .mat-dialog-content, html,
body, .page__body, .page__Body, .table-wrapper__container__table-wrapper {
  scrollbar-width: thin;
  scrollbar-color: #a8a8a8;
}
.custom-scrollbar::-webkit-scrollbar, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container::-webkit-scrollbar, .mat-dialog-content::-webkit-scrollbar, html::-webkit-scrollbar,
body::-webkit-scrollbar, .page__body::-webkit-scrollbar, .page__Body::-webkit-scrollbar, .table-wrapper__container__table-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: 0 0;
  padding-right: 3px;
  padding-bottom: 3px;
}
.custom-scrollbar::-webkit-scrollbar-thumb, .mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container::-webkit-scrollbar-thumb, .mat-dialog-content::-webkit-scrollbar-thumb, html::-webkit-scrollbar-thumb,
body::-webkit-scrollbar-thumb, .page__body::-webkit-scrollbar-thumb, .page__Body::-webkit-scrollbar-thumb, .table-wrapper__container__table-wrapper::-webkit-scrollbar-thumb {
  background: #a8a8a8;
  border-radius: 2px;
  margin-right: 3px;
  margin-bottom: 3px;
}

.transparent-backdrop {
  background-color: transparent !important;
}

.dialog-no-padding .mat-dialog-container {
  padding: 0;
}

.relative-dialog .mat-dialog-container {
  position: relative;
}

.mat-dialog-container {
  max-height: 100vh !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
  border-radius: 25px !important;
}
.mat-dialog-container > *:first-child {
  display: contents;
}

@media (max-width: 980px) {
  .mat-dialog-content {
    max-height: unset !important;
  }
}
.mat-dialog-content .mat-tab-body-content {
  overflow: auto !important;
}
.mat-dialog-content .mat-stepper-horizontal {
  max-height: 610px;
  display: flex;
  flex-direction: column;
}
.mat-dialog-content .mat-stepper-horizontal .mat-horizontal-content-container {
  overflow-y: auto;
}

@media (max-width: 980px) {
  .cdk-overlay-pane {
    max-width: 100vw !important;
  }
}

.mat-tab-group.full-height-tab-group {
  height: 100%;
}
.mat-tab-group.full-height-tab-group .mat-tab-body-wrapper {
  flex: 1;
}

.cdk-column-logo,
.cdk-column-avatar,
.cdk-column-photo {
  max-width: 40px !important;
  min-width: 40px !important;
  width: 40px !important;
  box-sizing: content-box !important;
  padding-right: 0 !important;
}

.mat-ink-bar {
  height: 4px !important;
}

.mdc-tab {
  letter-spacing: 0.02em !important;
}

.hide-cb {
  color: white !important;
  cursor: pointer !important;
}
.hide-cb .mat-pseudo-checkbox {
  display: none !important;
}

.mat-option-text .mat-icon {
  margin-left: 5px;
  font-size: 22px;
}

.mat-mdc-option {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.mat-mdc-option.mat-option-hide-checkbox .mat-pseudo-checkbox {
  display: none !important;
}

.mdc-list-item__primary-text {
  width: 100%;
}

mat-optgroup.sticky .mat-mdc-optgroup-label {
  position: sticky;
  z-index: 1;
  top: -8px;
  background-color: white;
  border-bottom: 1px solid #e0e0e0;
}

.paginator-start .mat-paginator-container {
  justify-content: flex-start;
}

.paginator-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
  padding-bottom: 7px;
}
.paginator-wrapper .mat-paginator-container {
  justify-content: flex-start;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}
.mat-mini-fab .mat-button-wrapper {
  line-height: 21px !important;
}

.mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
  padding-top: 12px;
}

.no-content-padding .mat-horizontal-content-container {
  padding-left: 0;
  padding-right: 0;
}

tremaze-file-selector-input + .mat-form-field-label-wrapper .mat-form-field-label.mat-empty {
  text-align: center;
  top: calc(50% - 21px);
}

tremaze-file-selector-input.noActions + .mat-form-field-label-wrapper .mat-form-field-label.mat-empty {
  text-align: center;
  top: 50%;
}

.mat-icon-button .lnr {
  font-size: 22px;
}

tremaze-routed-tab-view {
  display: flex;
  flex-direction: column;
  height: 100%;
}
tremaze-routed-tab-view .routeProxy {
  display: flex;
  flex-direction: column;
  height: 100%;
}
tremaze-routed-tab-view router-outlet ~ *:not(.routeProxy) {
  flex: 1;
  position: static;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
}
tremaze-routed-tab-view router-outlet ~ *:not(.routeProxy) > * {
  flex: 1;
}

.mat-form-field {
  display: block !important;
  font-size: 14px;
  letter-spacing: normal !important;
}

.mat-form-field-infix {
  width: auto !important;
}

.mat-form-field-outline-start {
  border-radius: 12px 0 0 12px !important;
  min-width: 12px !important;
}

.mat-form-field-outline-end {
  border-radius: 0 12px 12px 0 !important;
}

.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) translateX(0.65em) scale(0.75) !important;
}

fieldset > mat-form-field-appearance-outline:first-child,
fieldset > legend:first-child + .mat-form-field-appearance-outline {
  margin-top: 10px;
}

.mat-form-field-appearance-fill.mat-form-field-transparent > div {
  background-color: transparent !important;
}

.mat-mdc-form-field-icon-suffix:not(:has(.mdc-icon-button)) {
  padding-right: 0.5em !important;
  padding-top: 0.9em !important;
}

.autocomplete-clear-btn {
  height: 24px !important;
  width: 24px !important;
}
.autocomplete-clear-btn .lnr {
  position: absolute;
  left: 0;
  top: 0;
}
.autocomplete-clear-btn .mat-mdc-button-touch-target {
  height: 24px !important;
  width: 24px !important;
}

.mat-date-range-input-container {
  margin-top: 5px;
}

.comment__text > *:first-child {
  margin: 0 !important;
  padding: 0 !important;
}

.mat-progress-bar-invisible-background .mat-progress-bar-background,
.mat-progress-bar-invisible-background .mat-progress-bar-buffer {
  display: none !important;
}

.tox.tox-tinymce {
  flex: 1 !important;
}

.ng-animating editor > textarea {
  background-color: transparent !important;
  border: none !important;
}

.ordered-mat-list .mat-list-item-content {
  flex: 1;
}

.mat-mdc-list-item-unscoped-content {
  display: flex;
  align-items: center;
}

.mdc-list-item__primary-text {
  overflow: visible !important;
}

.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: white !important;
}

image-cropper .overlay {
  outline-color: transparent !important;
}

.mat-menu-item .lnr {
  transform: translateY(-3px);
  display: inline-block;
  font-size: 22px;
}

.mat-chip-list--nowrap .mat-chip-list-wrapper {
  display: flex;
  flex-wrap: nowrap;
}

.mat-chip {
  white-space: nowrap;
}

mat-icon {
  overflow: visible !important;
}

.badge {
  border-radius: 7px;
  text-align: center;
  font-size: 11px;
  padding: 1px 3px;
  min-width: 1.15em;
  display: inline-block;
  line-height: 1.5em;
  font-weight: 500;
}

tremaze-dashboard-sub-content tremaze-data-table > .table-wrapper__container > mat-card {
  box-shadow: none !important;
  padding: 0 !important;
  border-radius: 0 !important;
  margin: 0 !important;
}

.fc-timegrid-event {
  border-radius: 7px;
  box-shadow: none !important;
  border: 1px solid #000000 !important;
  background-color: #fff !important;
}

.tour-popover--Single-step .prev,
.tour-popover--Single-step .progress {
  visibility: hidden;
}
.tour-popover--with-padding .touranchor--is-active {
  padding: 10px;
  margin: -10px;
}

.tour-step mat-card {
  max-width: unset !important;
}

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px rgba(0, 0, 0, 0.1411764706), 0 1px 10px rgba(0, 0, 0, 0.1215686275);
  background-color: #fff;
  color: rgba(0, 0, 0, 0.8705882353);
}

.mat-calendar-body-selected {
  background-color: var(--mdc-filled-text-field-error-active-indicator-color);
  color: #fff;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
  background-color: var(--mat-ripple-color, rgba(0, 0, 0, 0.1));
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-drag-preview .mat-ripple-element, .cdk-drag-placeholder .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-app-background {
  background-color: var(--mat-app-background-color, var(--mat-app-background, transparent));
  color: var(--mat-app-text-color, var(--mat-app-on-background, inherit));
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: var(--mat-app-elevation-shadow-level-0, none);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: var(--mat-app-elevation-shadow-level-1, none);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: var(--mat-app-elevation-shadow-level-2, none);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: var(--mat-app-elevation-shadow-level-3, none);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: var(--mat-app-elevation-shadow-level-4, none);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: var(--mat-app-elevation-shadow-level-5, none);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: var(--mat-app-elevation-shadow-level-6, none);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: var(--mat-app-elevation-shadow-level-7, none);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: var(--mat-app-elevation-shadow-level-8, none);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: var(--mat-app-elevation-shadow-level-9, none);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: var(--mat-app-elevation-shadow-level-10, none);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: var(--mat-app-elevation-shadow-level-11, none);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: var(--mat-app-elevation-shadow-level-12, none);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: var(--mat-app-elevation-shadow-level-13, none);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: var(--mat-app-elevation-shadow-level-14, none);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: var(--mat-app-elevation-shadow-level-15, none);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: var(--mat-app-elevation-shadow-level-16, none);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: var(--mat-app-elevation-shadow-level-17, none);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: var(--mat-app-elevation-shadow-level-18, none);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: var(--mat-app-elevation-shadow-level-19, none);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: var(--mat-app-elevation-shadow-level-20, none);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: var(--mat-app-elevation-shadow-level-21, none);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: var(--mat-app-elevation-shadow-level-22, none);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: var(--mat-app-elevation-shadow-level-23, none);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: var(--mat-app-elevation-shadow-level-24, none);
}

html {
  font-family: Roboto;
}

.mat-theme-loaded-marker {
  display: none;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #00aa6b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #005d6b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #00aa6b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00aa6b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #005d6b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005d6b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #005d6b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005d6b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Roboto;
  --mat-option-label-text-line-height: 16px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto;
  --mat-optgroup-label-text-line-height: 16px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mat-ripple-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #00aa6b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #005d6b;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #00aa6b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00aa6b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-full-pseudo-checkbox-selected-icon-color: #005d6b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005d6b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #005d6b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005d6b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

.mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #fafafa;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #b0b0b0;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #b0b0b0;
}

html {
  --mat-app-background-color: #fafafa;
  --mat-app-text-color: rgba(0, 0, 0, 0.87);
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-option-label-text-font: Roboto;
  --mat-option-label-text-line-height: 16px;
  --mat-option-label-text-size: 16px;
  --mat-option-label-text-tracking: normal;
  --mat-option-label-text-weight: 400;
}

html {
  --mat-optgroup-label-text-font: Roboto;
  --mat-optgroup-label-text-line-height: 16px;
  --mat-optgroup-label-text-size: 16px;
  --mat-optgroup-label-text-tracking: normal;
  --mat-optgroup-label-text-weight: 400;
}

html {
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
}

html {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-card-title-text-font: Roboto;
  --mat-card-title-text-line-height: 32px;
  --mat-card-title-text-size: 20px;
  --mat-card-title-text-tracking: 0.0125em;
  --mat-card-title-text-weight: 500;
  --mat-card-subtitle-text-font: Roboto;
  --mat-card-subtitle-text-line-height: 22px;
  --mat-card-subtitle-text-size: 14px;
  --mat-card-subtitle-text-tracking: 0.0071428571em;
  --mat-card-subtitle-text-weight: 500;
}

html {
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #00aa6b;
  --mdc-linear-progress-track-color: rgba(0, 170, 107, 0.25);
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #005d6b;
  --mdc-linear-progress-track-color: rgba(0, 93, 107, 0.25);
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}

html {
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
}

html {
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-plain-tooltip-supporting-text-font: Roboto;
  --mdc-plain-tooltip-supporting-text-size: 12px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: normal;
}

html {
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
}

html {
  --mdc-filled-text-field-caret-color: #00aa6b;
  --mdc-filled-text-field-focus-active-indicator-color: #00aa6b;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 170, 107, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #00aa6b;
  --mdc-outlined-text-field-focus-outline-color: #00aa6b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 170, 107, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(0, 170, 107, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-state-layer-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-select-disabled-option-text-color: GrayText;
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.04;
  --mat-form-field-focus-state-layer-opacity: 0.08;
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #005d6b;
  --mdc-filled-text-field-focus-active-indicator-color: #005d6b;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 93, 107, 0.87);
  --mdc-outlined-text-field-caret-color: #005d6b;
  --mdc-outlined-text-field-focus-outline-color: #005d6b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 93, 107, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(0, 93, 107, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-form-field-container-height: 56px;
  --mat-form-field-filled-label-display: block;
  --mat-form-field-container-vertical-padding: 16px;
  --mat-form-field-filled-with-label-container-padding-top: 24px;
  --mat-form-field-filled-with-label-container-padding-bottom: 8px;
}

html {
  --mdc-filled-text-field-label-text-font: Roboto;
  --mdc-filled-text-field-label-text-size: 16px;
  --mdc-filled-text-field-label-text-tracking: normal;
  --mdc-filled-text-field-label-text-weight: 400;
  --mdc-outlined-text-field-label-text-font: Roboto;
  --mdc-outlined-text-field-label-text-size: 16px;
  --mdc-outlined-text-field-label-text-tracking: normal;
  --mdc-outlined-text-field-label-text-weight: 400;
  --mat-form-field-container-text-font: Roboto;
  --mat-form-field-container-text-line-height: 16px;
  --mat-form-field-container-text-size: 16px;
  --mat-form-field-container-text-tracking: normal;
  --mat-form-field-container-text-weight: 400;
  --mat-form-field-outlined-label-text-populated-size: 16px;
  --mat-form-field-subscript-text-font: Roboto;
  --mat-form-field-subscript-text-line-height: 1.3;
  --mat-form-field-subscript-text-size: 12px;
  --mat-form-field-subscript-text-tracking: normal;
  --mat-form-field-subscript-text-weight: 400;
}

html {
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 170, 107, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 93, 107, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}

html {
  --mat-select-arrow-transform: translateY(-8px);
}

html {
  --mat-select-trigger-text-font: Roboto;
  --mat-select-trigger-text-line-height: 16px;
  --mat-select-trigger-text-size: 16px;
  --mat-select-trigger-text-tracking: normal;
  --mat-select-trigger-text-weight: 400;
}

html {
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

html {
  --mat-autocomplete-background-color: white;
}

html {
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
}

html {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

html {
  --mdc-dialog-subhead-font: Roboto;
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-weight: 500;
  --mdc-dialog-subhead-tracking: 0.0125em;
  --mdc-dialog-supporting-text-font: Roboto;
  --mdc-dialog-supporting-text-line-height: 16px;
  --mdc-dialog-supporting-text-size: 16px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: normal;
}

.mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-selected-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-flat-disabled-selected-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-selected-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
  --mat-chip-selected-disabled-trailing-icon-color: #212121;
  --mat-chip-selected-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #00aa6b;
  --mdc-chip-elevated-selected-container-color: #00aa6b;
  --mdc-chip-elevated-disabled-container-color: #00aa6b;
  --mdc-chip-flat-disabled-selected-container-color: #00aa6b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #005d6b;
  --mdc-chip-elevated-selected-container-color: #005d6b;
  --mdc-chip-elevated-disabled-container-color: #005d6b;
  --mdc-chip-flat-disabled-selected-container-color: #005d6b;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-hover-state-layer-color: black;
  --mdc-chip-selected-hover-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: black;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-standard-chip {
  --mdc-chip-label-text-font: Roboto;
  --mdc-chip-label-text-line-height: 20px;
  --mdc-chip-label-text-size: 14px;
  --mdc-chip-label-text-tracking: 0.0178571429em;
  --mdc-chip-label-text-weight: 400;
}

html {
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
}
html .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}

html {
  --mdc-switch-selected-focus-state-layer-color: #00a363;
  --mdc-switch-selected-handle-color: #00a363;
  --mdc-switch-selected-hover-state-layer-color: #00a363;
  --mdc-switch-selected-pressed-state-layer-color: #00a363;
  --mdc-switch-selected-focus-handle-color: #007f3c;
  --mdc-switch-selected-hover-handle-color: #007f3c;
  --mdc-switch-selected-pressed-handle-color: #007f3c;
  --mdc-switch-selected-focus-track-color: #4dc497;
  --mdc-switch-selected-hover-track-color: #4dc497;
  --mdc-switch-selected-pressed-track-color: #4dc497;
  --mdc-switch-selected-track-color: #4dc497;
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: rgba(0, 0, 0, 0.87);
}
html .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #005563;
  --mdc-switch-selected-handle-color: #005563;
  --mdc-switch-selected-hover-state-layer-color: #005563;
  --mdc-switch-selected-pressed-state-layer-color: #005563;
  --mdc-switch-selected-focus-handle-color: #00303c;
  --mdc-switch-selected-hover-handle-color: #00303c;
  --mdc-switch-selected-pressed-handle-color: #00303c;
  --mdc-switch-selected-focus-track-color: #4d8e97;
  --mdc-switch-selected-hover-track-color: #4d8e97;
  --mdc-switch-selected-pressed-track-color: #4d8e97;
  --mdc-switch-selected-track-color: #4d8e97;
}
html .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e53935;
  --mdc-switch-selected-handle-color: #e53935;
  --mdc-switch-selected-hover-state-layer-color: #e53935;
  --mdc-switch-selected-pressed-state-layer-color: #e53935;
  --mdc-switch-selected-focus-handle-color: #b71c1c;
  --mdc-switch-selected-hover-handle-color: #b71c1c;
  --mdc-switch-selected-pressed-handle-color: #b71c1c;
  --mdc-switch-selected-focus-track-color: #e57373;
  --mdc-switch-selected-hover-track-color: #e57373;
  --mdc-switch-selected-pressed-track-color: #e57373;
  --mdc-switch-selected-track-color: #e57373;
}

html {
  --mdc-switch-state-layer-size: 40px;
}
html .mat-mdc-slide-toggle {
  --mat-switch-label-text-font: Roboto;
  --mat-switch-label-text-line-height: 20px;
  --mat-switch-label-text-size: 14px;
  --mat-switch-label-text-tracking: 0.0178571429em;
  --mat-switch-label-text-weight: 400;
}

html {
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #00aa6b;
  --mdc-radio-selected-hover-icon-color: #00aa6b;
  --mdc-radio-selected-icon-color: #00aa6b;
  --mdc-radio-selected-pressed-icon-color: #00aa6b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #00aa6b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #005d6b;
  --mdc-radio-selected-hover-icon-color: #005d6b;
  --mdc-radio-selected-icon-color: #005d6b;
  --mdc-radio-selected-pressed-icon-color: #005d6b;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #005d6b;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: black;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-radio-label-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mdc-radio-state-layer-size: 40px;
  --mat-radio-touch-target-display: block;
}

html {
  --mat-radio-label-text-font: Roboto;
  --mat-radio-label-text-line-height: 20px;
  --mat-radio-label-text-size: 14px;
  --mat-radio-label-text-tracking: 0.0178571429em;
  --mat-radio-label-text-weight: 400;
}

html {
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
}

html {
  --mdc-slider-handle-color: #00aa6b;
  --mdc-slider-focus-handle-color: #00aa6b;
  --mdc-slider-hover-handle-color: #00aa6b;
  --mdc-slider-active-track-color: #00aa6b;
  --mdc-slider-inactive-track-color: #00aa6b;
  --mdc-slider-with-tick-marks-inactive-container-color: #00aa6b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-label-container-color: #000;
  --mdc-slider-label-label-text-color: #fff;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-slider-ripple-color: #00aa6b;
  --mat-slider-hover-state-layer-color: rgba(0, 170, 107, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 170, 107, 0.2);
  --mat-slider-value-indicator-opacity: 0.6;
}
html .mat-accent {
  --mdc-slider-handle-color: #005d6b;
  --mdc-slider-focus-handle-color: #005d6b;
  --mdc-slider-hover-handle-color: #005d6b;
  --mdc-slider-active-track-color: #005d6b;
  --mdc-slider-inactive-track-color: #005d6b;
  --mdc-slider-with-tick-marks-inactive-container-color: #005d6b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #005d6b;
  --mat-slider-hover-state-layer-color: rgba(0, 93, 107, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 93, 107, 0.2);
}
html .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}

html {
  --mdc-slider-label-label-text-font: Roboto;
  --mdc-slider-label-label-text-size: 14px;
  --mdc-slider-label-label-text-line-height: 22px;
  --mdc-slider-label-label-text-tracking: 0.0071428571em;
  --mdc-slider-label-label-text-weight: 500;
}

html {
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
  --mat-menu-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-menu-item-label-text-font: Roboto;
  --mat-menu-item-label-text-size: 16px;
  --mat-menu-item-label-text-tracking: normal;
  --mat-menu-item-label-text-line-height: 16px;
  --mat-menu-item-label-text-weight: 400;
}

html {
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
}

html {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #00aa6b;
  --mdc-radio-selected-hover-icon-color: #00aa6b;
  --mdc-radio-selected-icon-color: #00aa6b;
  --mdc-radio-selected-pressed-icon-color: #00aa6b;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #005d6b;
  --mdc-radio-selected-hover-icon-color: #005d6b;
  --mdc-radio-selected-icon-color: #005d6b;
  --mdc-radio-selected-pressed-icon-color: #005d6b;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: black;
  --mdc-radio-disabled-unselected-icon-color: black;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-focus-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #00aa6b;
  --mdc-checkbox-selected-hover-icon-color: #00aa6b;
  --mdc-checkbox-selected-icon-color: #00aa6b;
  --mdc-checkbox-selected-pressed-icon-color: #00aa6b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00aa6b;
  --mdc-checkbox-selected-hover-state-layer-color: #00aa6b;
  --mdc-checkbox-selected-pressed-state-layer-color: #00aa6b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #005d6b;
  --mdc-checkbox-selected-hover-icon-color: #005d6b;
  --mdc-checkbox-selected-icon-color: #005d6b;
  --mdc-checkbox-selected-pressed-icon-color: #005d6b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #005d6b;
  --mdc-checkbox-selected-hover-state-layer-color: #005d6b;
  --mdc-checkbox-selected-pressed-state-layer-color: #005d6b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #00aa6b;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

html {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
  --mat-list-list-item-leading-icon-start-space: 16px;
  --mat-list-list-item-leading-icon-end-space: 32px;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mdc-list-list-item-label-text-font: Roboto;
  --mdc-list-list-item-label-text-line-height: 16px;
  --mdc-list-list-item-label-text-size: 16px;
  --mdc-list-list-item-label-text-tracking: normal;
  --mdc-list-list-item-label-text-weight: 400;
  --mdc-list-list-item-supporting-text-font: Roboto;
  --mdc-list-list-item-supporting-text-line-height: 20px;
  --mdc-list-list-item-supporting-text-size: 14px;
  --mdc-list-list-item-supporting-text-tracking: 0.0178571429em;
  --mdc-list-list-item-supporting-text-weight: 400;
  --mdc-list-list-item-trailing-supporting-text-font: Roboto;
  --mdc-list-list-item-trailing-supporting-text-line-height: 1.3;
  --mdc-list-list-item-trailing-supporting-text-size: 12px;
  --mdc-list-list-item-trailing-supporting-text-tracking: normal;
  --mdc-list-list-item-trailing-supporting-text-weight: 400;
}

.mdc-list-group__subheader {
  font: 400 16px / 28px Roboto;
  letter-spacing: 0.009375em;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
  --mat-paginator-form-field-container-height: 40px;
  --mat-paginator-form-field-container-vertical-padding: 8px;
  --mat-paginator-touch-target-display: block;
}

html {
  --mat-paginator-container-text-font: Roboto;
  --mat-paginator-container-text-line-height: 1.3;
  --mat-paginator-container-text-size: 12px;
  --mat-paginator-container-text-tracking: normal;
  --mat-paginator-container-text-weight: 400;
  --mat-paginator-select-trigger-text-size: 12px;
}

html {
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #00aa6b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #00aa6b;
  --mat-tab-header-active-ripple-color: #00aa6b;
  --mat-tab-header-inactive-ripple-color: #00aa6b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #00aa6b;
  --mat-tab-header-active-hover-label-text-color: #00aa6b;
  --mat-tab-header-active-focus-indicator-color: #00aa6b;
  --mat-tab-header-active-hover-indicator-color: #00aa6b;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #005d6b;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #005d6b;
  --mat-tab-header-active-ripple-color: #005d6b;
  --mat-tab-header-inactive-ripple-color: #005d6b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #005d6b;
  --mat-tab-header-active-hover-label-text-color: #005d6b;
  --mat-tab-header-active-focus-indicator-color: #005d6b;
  --mat-tab-header-active-hover-indicator-color: #005d6b;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: black;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #00aa6b;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #005d6b;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

.mat-mdc-tab-header {
  --mat-tab-header-label-text-font: Roboto;
  --mat-tab-header-label-text-size: 14px;
  --mat-tab-header-label-text-tracking: normal;
  --mat-tab-header-label-text-line-height: 14px;
  --mat-tab-header-label-text-weight: 400;
}

html {
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #005d6b;
  --mdc-checkbox-selected-hover-icon-color: #005d6b;
  --mdc-checkbox-selected-icon-color: #005d6b;
  --mdc-checkbox-selected-pressed-icon-color: #005d6b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #005d6b;
  --mdc-checkbox-selected-hover-state-layer-color: #005d6b;
  --mdc-checkbox-selected-pressed-state-layer-color: #005d6b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mat-checkbox-disabled-label-color: rgba(0, 0, 0, 0.38);
  --mat-checkbox-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #00aa6b;
  --mdc-checkbox-selected-hover-icon-color: #00aa6b;
  --mdc-checkbox-selected-icon-color: #00aa6b;
  --mdc-checkbox-selected-pressed-icon-color: #00aa6b;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00aa6b;
  --mdc-checkbox-selected-hover-state-layer-color: #00aa6b;
  --mdc-checkbox-selected-pressed-state-layer-color: #00aa6b;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

html {
  --mdc-checkbox-state-layer-size: 40px;
  --mat-checkbox-touch-target-display: block;
}

html {
  --mat-checkbox-label-text-font: Roboto;
  --mat-checkbox-label-text-line-height: 20px;
  --mat-checkbox-label-text-size: 14px;
  --mat-checkbox-label-text-tracking: 0.0178571429em;
  --mat-checkbox-label-text-weight: 400;
}

html {
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
}

html {
  --mdc-text-button-label-text-color: black;
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-text-button-state-layer-color: black;
  --mat-text-button-disabled-state-layer-color: black;
  --mat-text-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-focus-state-layer-opacity: 0.12;
  --mat-text-button-pressed-state-layer-opacity: 0.12;
  --mdc-filled-button-container-color: white;
  --mdc-filled-button-label-text-color: black;
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-filled-button-state-layer-color: black;
  --mat-filled-button-disabled-state-layer-color: black;
  --mat-filled-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-filled-button-focus-state-layer-opacity: 0.12;
  --mat-filled-button-pressed-state-layer-opacity: 0.12;
  --mdc-protected-button-container-color: white;
  --mdc-protected-button-label-text-color: black;
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mat-protected-button-state-layer-color: black;
  --mat-protected-button-disabled-state-layer-color: black;
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.04;
  --mat-protected-button-focus-state-layer-opacity: 0.12;
  --mat-protected-button-pressed-state-layer-opacity: 0.12;
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-label-text-color: black;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: black;
  --mat-outlined-button-disabled-state-layer-color: black;
  --mat-outlined-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.04;
  --mat-outlined-button-focus-state-layer-opacity: 0.12;
  --mat-outlined-button-pressed-state-layer-opacity: 0.12;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #00aa6b;
  --mat-text-button-state-layer-color: #00aa6b;
  --mat-text-button-ripple-color: rgba(0, 170, 107, 0.1);
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #005d6b;
  --mat-text-button-state-layer-color: #005d6b;
  --mat-text-button-ripple-color: rgba(0, 93, 107, 0.1);
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #00aa6b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #005d6b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #00aa6b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #005d6b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #00aa6b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #00aa6b;
  --mat-outlined-button-ripple-color: rgba(0, 170, 107, 0.1);
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #005d6b;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #005d6b;
  --mat-outlined-button-ripple-color: rgba(0, 93, 107, 0.1);
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mdc-text-button-container-height: 36px;
  --mdc-filled-button-container-height: 36px;
  --mdc-protected-button-container-height: 36px;
  --mdc-outlined-button-container-height: 36px;
  --mat-text-button-touch-target-display: block;
  --mat-filled-button-touch-target-display: block;
  --mat-protected-button-touch-target-display: block;
  --mat-outlined-button-touch-target-display: block;
}

html {
  --mdc-text-button-label-text-font: Roboto;
  --mdc-text-button-label-text-size: 14px;
  --mdc-text-button-label-text-tracking: normal;
  --mdc-text-button-label-text-weight: 400;
  --mdc-text-button-label-text-transform: none;
  --mdc-filled-button-label-text-font: Roboto;
  --mdc-filled-button-label-text-size: 14px;
  --mdc-filled-button-label-text-tracking: normal;
  --mdc-filled-button-label-text-weight: 400;
  --mdc-filled-button-label-text-transform: none;
  --mdc-protected-button-label-text-font: Roboto;
  --mdc-protected-button-label-text-size: 14px;
  --mdc-protected-button-label-text-tracking: normal;
  --mdc-protected-button-label-text-weight: 400;
  --mdc-protected-button-label-text-transform: none;
  --mdc-outlined-button-label-text-font: Roboto;
  --mdc-outlined-button-label-text-size: 14px;
  --mdc-outlined-button-label-text-tracking: normal;
  --mdc-outlined-button-label-text-weight: 400;
  --mdc-outlined-button-label-text-transform: none;
}

html {
  --mdc-icon-button-icon-size: 24px;
}

html {
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
  --mat-icon-button-state-layer-color: black;
  --mat-icon-button-disabled-state-layer-color: black;
  --mat-icon-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.04;
  --mat-icon-button-focus-state-layer-opacity: 0.12;
  --mat-icon-button-pressed-state-layer-opacity: 0.12;
}
html .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #00aa6b;
  --mat-icon-button-state-layer-color: #00aa6b;
  --mat-icon-button-ripple-color: rgba(0, 170, 107, 0.1);
}
html .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #005d6b;
  --mat-icon-button-state-layer-color: #005d6b;
  --mat-icon-button-ripple-color: rgba(0, 93, 107, 0.1);
}
html .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}

html {
  --mat-icon-button-touch-target-display: block;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

html {
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

html {
  --mdc-fab-container-color: white;
  --mat-fab-foreground-color: black;
  --mat-fab-state-layer-color: black;
  --mat-fab-disabled-state-layer-color: black;
  --mat-fab-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.04;
  --mat-fab-focus-state-layer-opacity: 0.12;
  --mat-fab-pressed-state-layer-opacity: 0.12;
  --mat-fab-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
  --mdc-fab-small-container-color: white;
  --mat-fab-small-foreground-color: black;
  --mat-fab-small-state-layer-color: black;
  --mat-fab-small-disabled-state-layer-color: black;
  --mat-fab-small-ripple-color: rgba(0, 0, 0, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.04;
  --mat-fab-small-focus-state-layer-opacity: 0.12;
  --mat-fab-small-pressed-state-layer-opacity: 0.12;
  --mat-fab-small-disabled-state-container-color: rgba(0, 0, 0, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(0, 0, 0, 0.38);
}
html .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #00aa6b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #005d6b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #00aa6b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #005d6b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
html .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}

html {
  --mat-fab-touch-target-display: block;
  --mat-fab-small-touch-target-display: block;
}

html {
  --mdc-extended-fab-label-text-font: Roboto;
  --mdc-extended-fab-label-text-size: 14px;
  --mdc-extended-fab-label-text-tracking: normal;
  --mdc-extended-fab-label-text-weight: 400;
}

html {
  --mdc-snackbar-container-shape: 4px;
}

html {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #005d6b;
}

html {
  --mdc-snackbar-supporting-text-font: Roboto;
  --mdc-snackbar-supporting-text-line-height: 20px;
  --mdc-snackbar-supporting-text-size: 14px;
  --mdc-snackbar-supporting-text-weight: 400;
}

html {
  --mat-table-row-item-outline-width: 1px;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

html {
  --mat-table-header-headline-font: Roboto;
  --mat-table-header-headline-line-height: 22px;
  --mat-table-header-headline-size: 14px;
  --mat-table-header-headline-weight: 500;
  --mat-table-header-headline-tracking: 0.0071428571em;
  --mat-table-row-item-label-text-font: Roboto;
  --mat-table-row-item-label-text-line-height: 20px;
  --mat-table-row-item-label-text-size: 14px;
  --mat-table-row-item-label-text-weight: 400;
  --mat-table-row-item-label-text-tracking: 0.0178571429em;
  --mat-table-footer-supporting-text-font: Roboto;
  --mat-table-footer-supporting-text-line-height: 20px;
  --mat-table-footer-supporting-text-size: 14px;
  --mat-table-footer-supporting-text-weight: 400;
  --mat-table-footer-supporting-text-tracking: 0.0178571429em;
}

html {
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
}

html {
  --mdc-circular-progress-active-indicator-color: #00aa6b;
}
html .mat-accent {
  --mdc-circular-progress-active-indicator-color: #005d6b;
}
html .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}

html {
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
}

html {
  --mat-badge-background-color: #00aa6b;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #b9b9b9;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #005d6b;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}

html {
  --mat-badge-text-font: Roboto;
  --mat-badge-line-height: 22px;
  --mat-badge-text-size: 12px;
  --mat-badge-text-weight: 600;
  --mat-badge-small-size-text-size: 9px;
  --mat-badge-small-size-line-height: 16px;
  --mat-badge-large-size-text-size: 24px;
  --mat-badge-large-size-line-height: 28px;
}

html {
  --mat-bottom-sheet-container-shape: 4px;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-bottom-sheet-container-text-font: Roboto;
  --mat-bottom-sheet-container-text-line-height: 20px;
  --mat-bottom-sheet-container-text-size: 14px;
  --mat-bottom-sheet-container-text-tracking: 0.0178571429em;
  --mat-bottom-sheet-container-text-weight: 400;
}

html {
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #eeeeee;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #e0e0e0;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #bdbdbd;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-legacy-button-toggle-label-text-font: Roboto;
  --mat-legacy-button-toggle-label-text-line-height: 16px;
  --mat-legacy-button-toggle-label-text-size: 16px;
  --mat-legacy-button-toggle-label-text-tracking: normal;
  --mat-legacy-button-toggle-label-text-weight: 400;
  --mat-standard-button-toggle-label-text-font: Roboto;
  --mat-standard-button-toggle-label-text-line-height: 16px;
  --mat-standard-button-toggle-label-text-size: 16px;
  --mat-standard-button-toggle-label-text-tracking: normal;
  --mat-standard-button-toggle-label-text-weight: 400;
}

html {
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #00aa6b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 170, 107, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 170, 107, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 170, 107, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #00aa6b;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 170, 107, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-text-color: black;
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #005d6b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 93, 107, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 93, 107, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 93, 107, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 93, 107, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #005d6b;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}

.mat-calendar-controls {
  --mat-icon-button-touch-target-display: none;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}

html {
  --mat-datepicker-calendar-text-font: Roboto;
  --mat-datepicker-calendar-text-size: 13px;
  --mat-datepicker-calendar-body-label-text-size: 14px;
  --mat-datepicker-calendar-body-label-text-weight: 400;
  --mat-datepicker-calendar-period-button-text-size: 14px;
  --mat-datepicker-calendar-period-button-text-weight: 400;
  --mat-datepicker-calendar-header-text-size: 11px;
  --mat-datepicker-calendar-header-text-weight: 400;
}

html {
  --mat-divider-width: 1px;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(0, 0, 0, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-expansion-header-text-font: Roboto;
  --mat-expansion-header-text-size: 14px;
  --mat-expansion-header-text-weight: 500;
  --mat-expansion-header-text-line-height: inherit;
  --mat-expansion-header-text-tracking: inherit;
  --mat-expansion-container-text-font: Roboto;
  --mat-expansion-container-text-line-height: 20px;
  --mat-expansion-container-text-size: 14px;
  --mat-expansion-container-text-tracking: 0.0178571429em;
  --mat-expansion-container-text-weight: 400;
}

html {
  --mat-grid-list-tile-header-primary-text-size: 14px;
  --mat-grid-list-tile-header-secondary-text-size: 12px;
  --mat-grid-list-tile-footer-primary-text-size: 14px;
  --mat-grid-list-tile-footer-secondary-text-size: 12px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #00aa6b;
}
.mat-icon.mat-accent {
  --mat-icon-color: #005d6b;
}
.mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}

html {
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #00aa6b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #00aa6b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #00aa6b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #005d6b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #005d6b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #005d6b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

html {
  --mat-stepper-container-text-font: Roboto;
  --mat-stepper-header-label-text-font: Roboto;
  --mat-stepper-header-label-text-size: 14px;
  --mat-stepper-header-label-text-weight: 400;
  --mat-stepper-header-error-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-size: 16px;
  --mat-stepper-header-selected-state-label-text-weight: 400;
}

html {
  --mat-sort-arrow-color: #757575;
}

html {
  --mat-toolbar-container-background-color: whitesmoke;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #00aa6b;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #005d6b;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

html {
  --mat-toolbar-title-text-font: Roboto;
  --mat-toolbar-title-text-line-height: 32px;
  --mat-toolbar-title-text-size: 20px;
  --mat-toolbar-title-text-tracking: 0.0125em;
  --mat-toolbar-title-text-weight: 500;
}

html {
  --mat-tree-container-background-color: white;
  --mat-tree-node-text-color: rgba(0, 0, 0, 0.87);
}

html {
  --mat-tree-node-min-height: 48px;
}

html {
  --mat-tree-node-text-font: Roboto;
  --mat-tree-node-text-size: 14px;
  --mat-tree-node-text-weight: 400;
}

.event-week-item {
  border-color: #00aa6b !important;
}

#login-page h1 {
  color: #00aa6b;
}

.page__head {
  background: #00aa6b;
}

.page__actions {
  background: white;
}

.primary-hover:hover {
  background-color: rgba(0, 170, 107, 0.1) !important;
}

.primary-bg {
  background-color: #00aa6b;
}

.primary-bg-light {
  background-color: rgba(0, 170, 107, 0.1);
}

.accent-bg {
  background-color: #005d6b;
}

.accent-bg-light {
  background-color: rgba(0, 93, 107, 0.1);
}

.primary-fg {
  color: white;
}

.primary-color {
  color: #00aa6b !important;
}

.accent-fg {
  color: white;
}

.accent-color {
  color: #005d6b !important;
}

.light-hint-text {
  color: rgba(0, 0, 0, 0.38);
}

.lighter-text {
  color: #4b4b4b;
}

.highlighted-textbox {
  color: #4b4b4b;
  background-color: #f5f5f5;
  border-color: #e5e5e5;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  border-radius: 10px;
}
.highlighted-textbox > *:first-child {
  margin: 0;
}

.initials-circle {
  background: #00aa6b;
  color: white;
}

.initials-circle.accent {
  background: #005d6b;
  color: white;
}

.initials-circle.warn {
  background: #f44336;
  color: white;
}

blockquote {
  border-color: #00aa6b;
}

.cdk-drag-preview {
  background: white;
}

.dark-theme {
  --mat-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-option-selected-state-label-text-color: #00aa6b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-optgroup-label-text-color: white;
  --mat-full-pseudo-checkbox-selected-icon-color: #005d6b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005d6b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
  --mat-app-background-color: #303030;
  --mat-app-text-color: white;
  --mat-app-elevation-shadow-level-0: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-3: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-4: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-5: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-6: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-7: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-9: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-10: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-11: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-12: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-13: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-14: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-15: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-16: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-17: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-18: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-19: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-20: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-21: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-22: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-23: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
  --mat-app-elevation-shadow-level-24: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mdc-elevated-card-container-shape: 4px;
  --mdc-outlined-card-container-shape: 4px;
  --mdc-outlined-card-outline-width: 1px;
  --mdc-elevated-card-container-color: #424242;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #424242;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
  --mdc-linear-progress-active-indicator-height: 4px;
  --mdc-linear-progress-track-height: 4px;
  --mdc-linear-progress-track-shape: 0;
  --mdc-plain-tooltip-container-shape: 4px;
  --mdc-plain-tooltip-supporting-text-line-height: 16px;
  --mdc-plain-tooltip-container-color: #616161;
  --mdc-plain-tooltip-supporting-text-color: #fff;
  --mdc-filled-text-field-active-indicator-height: 1px;
  --mdc-filled-text-field-focus-active-indicator-height: 2px;
  --mdc-filled-text-field-container-shape: 4px;
  --mdc-outlined-text-field-outline-width: 1px;
  --mdc-outlined-text-field-focus-outline-width: 2px;
  --mdc-outlined-text-field-container-shape: 4px;
  --mdc-filled-text-field-caret-color: #00aa6b;
  --mdc-filled-text-field-focus-active-indicator-color: #00aa6b;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 170, 107, 0.87);
  --mdc-filled-text-field-container-color: #4a4a4a;
  --mdc-filled-text-field-disabled-container-color: #464646;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-hover-label-text-color: #f44336;
  --mdc-filled-text-field-error-focus-label-text-color: #f44336;
  --mdc-filled-text-field-error-label-text-color: #f44336;
  --mdc-filled-text-field-error-caret-color: #f44336;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-error-hover-active-indicator-color: #f44336;
  --mdc-outlined-text-field-caret-color: #00aa6b;
  --mdc-outlined-text-field-focus-outline-color: #00aa6b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 170, 107, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: #f44336;
  --mdc-outlined-text-field-error-focus-label-text-color: #f44336;
  --mdc-outlined-text-field-error-label-text-color: #f44336;
  --mdc-outlined-text-field-error-hover-label-text-color: #f44336;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: #f44336;
  --mdc-outlined-text-field-error-hover-outline-color: #f44336;
  --mdc-outlined-text-field-error-outline-color: #f44336;
  --mat-form-field-focus-select-arrow-color: rgba(0, 170, 107, 0.87);
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-state-layer-color: rgba(255, 255, 255, 0.87);
  --mat-form-field-error-text-color: #f44336;
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mat-form-field-select-disabled-option-text-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-leading-icon-color: unset;
  --mat-form-field-disabled-leading-icon-color: unset;
  --mat-form-field-trailing-icon-color: unset;
  --mat-form-field-disabled-trailing-icon-color: unset;
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-error-trailing-icon-color: unset;
  --mat-form-field-enabled-select-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-form-field-disabled-select-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-form-field-hover-state-layer-opacity: 0.08;
  --mat-form-field-focus-state-layer-opacity: 0.24;
  --mat-select-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 170, 107, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-autocomplete-container-shape: 4px;
  --mat-autocomplete-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mat-autocomplete-background-color: #424242;
  --mdc-dialog-container-shape: 4px;
  --mat-dialog-container-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-dialog-container-max-width: 80vw;
  --mat-dialog-container-small-max-width: 80vw;
  --mat-dialog-container-min-width: 0;
  --mat-dialog-actions-alignment: start;
  --mat-dialog-actions-padding: 8px;
  --mat-dialog-content-padding: 20px 24px;
  --mat-dialog-with-actions-content-padding: 20px 24px;
  --mat-dialog-headline-padding: 0 24px 9px;
  --mdc-dialog-container-color: #424242;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
  --mdc-switch-disabled-selected-icon-opacity: 0.38;
  --mdc-switch-disabled-track-opacity: 0.12;
  --mdc-switch-disabled-unselected-icon-opacity: 0.38;
  --mdc-switch-handle-height: 20px;
  --mdc-switch-handle-shape: 10px;
  --mdc-switch-handle-width: 20px;
  --mdc-switch-selected-icon-size: 18px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-shape: 7px;
  --mdc-switch-track-width: 36px;
  --mdc-switch-unselected-icon-size: 18px;
  --mdc-switch-selected-focus-state-layer-opacity: 0.12;
  --mdc-switch-selected-hover-state-layer-opacity: 0.04;
  --mdc-switch-selected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-unselected-focus-state-layer-opacity: 0.12;
  --mdc-switch-unselected-hover-state-layer-opacity: 0.04;
  --mdc-switch-unselected-pressed-state-layer-opacity: 0.1;
  --mdc-switch-selected-focus-state-layer-color: #4dc497;
  --mdc-switch-selected-handle-color: #4dc497;
  --mdc-switch-selected-hover-state-layer-color: #4dc497;
  --mdc-switch-selected-pressed-state-layer-color: #4dc497;
  --mdc-switch-selected-focus-handle-color: #80d5b5;
  --mdc-switch-selected-hover-handle-color: #80d5b5;
  --mdc-switch-selected-pressed-handle-color: #80d5b5;
  --mdc-switch-selected-focus-track-color: #00a363;
  --mdc-switch-selected-hover-track-color: #00a363;
  --mdc-switch-selected-pressed-track-color: #00a363;
  --mdc-switch-selected-track-color: #00a363;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: #fff;
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-radio-disabled-selected-icon-opacity: 0.38;
  --mdc-radio-disabled-unselected-icon-opacity: 0.38;
  --mdc-radio-state-layer-size: 40px;
  --mdc-slider-active-track-height: 6px;
  --mdc-slider-active-track-shape: 9999px;
  --mdc-slider-handle-height: 20px;
  --mdc-slider-handle-shape: 50%;
  --mdc-slider-handle-width: 20px;
  --mdc-slider-inactive-track-height: 4px;
  --mdc-slider-inactive-track-shape: 9999px;
  --mdc-slider-with-overlap-handle-outline-width: 1px;
  --mdc-slider-with-tick-marks-active-container-opacity: 0.6;
  --mdc-slider-with-tick-marks-container-shape: 50%;
  --mdc-slider-with-tick-marks-container-size: 2px;
  --mdc-slider-with-tick-marks-inactive-container-opacity: 0.6;
  --mdc-slider-handle-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mat-slider-value-indicator-width: auto;
  --mat-slider-value-indicator-height: 32px;
  --mat-slider-value-indicator-caret-display: block;
  --mat-slider-value-indicator-border-radius: 4px;
  --mat-slider-value-indicator-padding: 0 12px;
  --mat-slider-value-indicator-text-transform: none;
  --mat-slider-value-indicator-container-transform: translateX(-50%);
  --mdc-slider-handle-color: #00aa6b;
  --mdc-slider-focus-handle-color: #00aa6b;
  --mdc-slider-hover-handle-color: #00aa6b;
  --mdc-slider-active-track-color: #00aa6b;
  --mdc-slider-inactive-track-color: #00aa6b;
  --mdc-slider-with-tick-marks-inactive-container-color: #00aa6b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-label-container-color: #fff;
  --mdc-slider-label-label-text-color: #000;
  --mdc-slider-with-overlap-handle-outline-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-slider-ripple-color: #00aa6b;
  --mat-slider-hover-state-layer-color: rgba(0, 170, 107, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 170, 107, 0.2);
  --mat-slider-value-indicator-opacity: 0.9;
  --mat-menu-container-shape: 4px;
  --mat-menu-divider-bottom-spacing: 0;
  --mat-menu-divider-top-spacing: 0;
  --mat-menu-item-spacing: 16px;
  --mat-menu-item-icon-size: 24px;
  --mat-menu-item-leading-spacing: 16px;
  --mat-menu-item-trailing-spacing: 16px;
  --mat-menu-item-with-icon-leading-spacing: 16px;
  --mat-menu-item-with-icon-trailing-spacing: 16px;
  --mat-menu-base-elevation-level: 8;
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #424242;
  --mat-menu-divider-color: rgba(255, 255, 255, 0.12);
  --mdc-list-list-item-container-shape: 0;
  --mdc-list-list-item-leading-avatar-shape: 50%;
  --mdc-list-list-item-container-color: transparent;
  --mdc-list-list-item-selected-container-color: transparent;
  --mdc-list-list-item-leading-avatar-color: transparent;
  --mdc-list-list-item-leading-icon-size: 24px;
  --mdc-list-list-item-leading-avatar-size: 40px;
  --mdc-list-list-item-trailing-icon-size: 24px;
  --mdc-list-list-item-disabled-state-layer-color: transparent;
  --mdc-list-list-item-disabled-state-layer-opacity: 0;
  --mdc-list-list-item-disabled-label-text-opacity: 0.38;
  --mdc-list-list-item-disabled-leading-icon-opacity: 0.38;
  --mdc-list-list-item-disabled-trailing-icon-opacity: 0.38;
  --mat-list-active-indicator-color: transparent;
  --mat-list-active-indicator-shape: 4px;
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #424242;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
  --mdc-secondary-navigation-tab-container-height: 48px;
  --mdc-tab-indicator-active-indicator-height: 2px;
  --mdc-tab-indicator-active-indicator-shape: 0;
  --mat-tab-header-divider-color: transparent;
  --mat-tab-header-divider-height: 0;
  --mdc-checkbox-disabled-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-selected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0.16;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.04;
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0.16;
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #005d6b;
  --mdc-checkbox-selected-hover-icon-color: #005d6b;
  --mdc-checkbox-selected-icon-color: #005d6b;
  --mdc-checkbox-selected-pressed-icon-color: #005d6b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #005d6b;
  --mdc-checkbox-selected-hover-state-layer-color: #005d6b;
  --mdc-checkbox-selected-pressed-state-layer-color: #005d6b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
  --mat-checkbox-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-checkbox-label-text-color: white;
  --mdc-text-button-container-shape: 4px;
  --mdc-text-button-keep-touch-target: false;
  --mdc-filled-button-container-shape: 4px;
  --mdc-filled-button-keep-touch-target: false;
  --mdc-protected-button-container-shape: 4px;
  --mdc-protected-button-container-elevation-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-container-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-focus-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-hover-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mdc-protected-button-pressed-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-keep-touch-target: false;
  --mdc-outlined-button-outline-width: 1px;
  --mdc-outlined-button-container-shape: 4px;
  --mat-text-button-horizontal-padding: 8px;
  --mat-text-button-with-icon-horizontal-padding: 8px;
  --mat-text-button-icon-spacing: 8px;
  --mat-text-button-icon-offset: 0;
  --mat-filled-button-horizontal-padding: 16px;
  --mat-filled-button-icon-spacing: 8px;
  --mat-filled-button-icon-offset: -4px;
  --mat-protected-button-horizontal-padding: 16px;
  --mat-protected-button-icon-spacing: 8px;
  --mat-protected-button-icon-offset: -4px;
  --mat-outlined-button-horizontal-padding: 15px;
  --mat-outlined-button-icon-spacing: 8px;
  --mat-outlined-button-icon-offset: -4px;
  --mdc-text-button-label-text-color: white;
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-text-button-state-layer-color: white;
  --mat-text-button-disabled-state-layer-color: white;
  --mat-text-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-text-button-hover-state-layer-opacity: 0.08;
  --mat-text-button-focus-state-layer-opacity: 0.24;
  --mat-text-button-pressed-state-layer-opacity: 0.24;
  --mdc-filled-button-container-color: #424242;
  --mdc-filled-button-label-text-color: white;
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-disabled-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-filled-button-hover-state-layer-opacity: 0.08;
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;
  --mdc-protected-button-container-color: #424242;
  --mdc-protected-button-label-text-color: white;
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-disabled-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-protected-button-hover-state-layer-opacity: 0.08;
  --mat-protected-button-focus-state-layer-opacity: 0.24;
  --mat-protected-button-pressed-state-layer-opacity: 0.24;
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-label-text-color: white;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: white;
  --mat-outlined-button-disabled-state-layer-color: white;
  --mat-outlined-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-outlined-button-hover-state-layer-opacity: 0.08;
  --mat-outlined-button-focus-state-layer-opacity: 0.24;
  --mat-outlined-button-pressed-state-layer-opacity: 0.24;
  --mdc-icon-button-icon-size: 24px;
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
  --mat-icon-button-state-layer-color: white;
  --mat-icon-button-disabled-state-layer-color: white;
  --mat-icon-button-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-icon-button-hover-state-layer-opacity: 0.08;
  --mat-icon-button-focus-state-layer-opacity: 0.24;
  --mat-icon-button-pressed-state-layer-opacity: 0.24;
  --mdc-fab-container-shape: 50%;
  --mdc-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-container-shape: 50%;
  --mdc-fab-small-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-fab-small-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-container-height: 48px;
  --mdc-extended-fab-container-shape: 24px;
  --mdc-extended-fab-container-elevation-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-focus-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-hover-container-elevation-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --mdc-extended-fab-pressed-container-elevation-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
  --mdc-fab-container-color: #424242;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-disabled-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-hover-state-layer-opacity: 0.08;
  --mat-fab-focus-state-layer-opacity: 0.24;
  --mat-fab-pressed-state-layer-opacity: 0.24;
  --mat-fab-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-fab-small-container-color: #424242;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-disabled-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
  --mat-fab-small-hover-state-layer-opacity: 0.08;
  --mat-fab-small-focus-state-layer-opacity: 0.24;
  --mat-fab-small-pressed-state-layer-opacity: 0.24;
  --mat-fab-small-disabled-state-container-color: rgba(255, 255, 255, 0.12);
  --mat-fab-small-disabled-state-foreground-color: rgba(255, 255, 255, 0.5);
  --mdc-snackbar-container-shape: 4px;
  --mdc-snackbar-container-color: #d9d9d9;
  --mdc-snackbar-supporting-text-color: rgba(66, 66, 66, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-width: 1px;
  --mat-table-background-color: #424242;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-circular-progress-active-indicator-width: 4px;
  --mdc-circular-progress-size: 48px;
  --mdc-circular-progress-active-indicator-color: #00aa6b;
  --mat-badge-container-shape: 50%;
  --mat-badge-container-size: unset;
  --mat-badge-small-size-container-size: unset;
  --mat-badge-large-size-container-size: unset;
  --mat-badge-legacy-container-size: 22px;
  --mat-badge-legacy-small-size-container-size: 16px;
  --mat-badge-legacy-large-size-container-size: 28px;
  --mat-badge-container-offset: -11px 0;
  --mat-badge-small-size-container-offset: -8px 0;
  --mat-badge-large-size-container-offset: -14px 0;
  --mat-badge-container-overlap-offset: -11px;
  --mat-badge-small-size-container-overlap-offset: -8px;
  --mat-badge-large-size-container-overlap-offset: -14px;
  --mat-badge-container-padding: 0;
  --mat-badge-small-size-container-padding: 0;
  --mat-badge-large-size-container-padding: 0;
  --mat-badge-background-color: #00aa6b;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #6e6e6e;
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-bottom-sheet-container-shape: 4px;
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #424242;
  --mat-legacy-button-toggle-height: 36px;
  --mat-legacy-button-toggle-shape: 2px;
  --mat-legacy-button-toggle-focus-state-layer-opacity: 1;
  --mat-standard-button-toggle-shape: 4px;
  --mat-standard-button-toggle-hover-state-layer-opacity: 0.04;
  --mat-standard-button-toggle-focus-state-layer-opacity: 0.12;
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #212121;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: black;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #424242;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #212121;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #424242;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #424242;
  --mat-standard-button-toggle-divider-color: #595959;
  --mat-datepicker-calendar-container-shape: 4px;
  --mat-datepicker-calendar-container-touch-shape: 4px;
  --mat-datepicker-calendar-container-elevation-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-container-touch-elevation-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #00aa6b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 170, 107, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 170, 107, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 170, 107, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #00aa6b;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 170, 107, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-text-color: white;
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #424242;
  --mat-datepicker-calendar-container-text-color: white;
  --mat-divider-width: 1px;
  --mat-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-container-shape: 4px;
  --mat-expansion-legacy-header-indicator-display: inline-block;
  --mat-expansion-header-indicator-display: none;
  --mat-expansion-container-background-color: #424242;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
  --mat-icon-color: inherit;
  --mat-sidenav-container-shape: 0;
  --mat-sidenav-container-elevation-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-width: auto;
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #424242;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #303030;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(189, 189, 189, 0.6);
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #00aa6b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #00aa6b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #00aa6b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #424242;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(255, 255, 255, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: #f44336;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: #f44336;
  --mat-stepper-header-error-state-icon-background-color: transparent;
  --mat-sort-arrow-color: #c6c6c6;
  --mat-toolbar-container-background-color: #212121;
  --mat-toolbar-container-text-color: white;
  --mat-tree-container-background-color: #424242;
  --mat-tree-node-text-color: white;
}
.dark-theme .mat-accent {
  --mat-option-selected-state-label-text-color: #005d6b;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.dark-theme .mat-warn {
  --mat-option-selected-state-label-text-color: #f44336;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}
.dark-theme .mat-primary {
  --mat-full-pseudo-checkbox-selected-icon-color: #00aa6b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #00aa6b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.dark-theme .mat-accent {
  --mat-full-pseudo-checkbox-selected-icon-color: #005d6b;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #005d6b;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.dark-theme .mat-warn {
  --mat-full-pseudo-checkbox-selected-icon-color: #f44336;
  --mat-full-pseudo-checkbox-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.7);
  --mat-full-pseudo-checkbox-disabled-selected-checkmark-color: #303030;
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #686868;
  --mat-full-pseudo-checkbox-disabled-selected-icon-color: #686868;
  --mat-minimal-pseudo-checkbox-selected-checkmark-color: #f44336;
  --mat-minimal-pseudo-checkbox-disabled-selected-checkmark-color: #686868;
}
.dark-theme .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #00aa6b;
  --mdc-linear-progress-track-color: rgba(0, 170, 107, 0.25);
}
.dark-theme .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #005d6b;
  --mdc-linear-progress-track-color: rgba(0, 93, 107, 0.25);
}
.dark-theme .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: #f44336;
  --mdc-linear-progress-track-color: rgba(244, 67, 54, 0.25);
}
.dark-theme .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #005d6b;
  --mdc-filled-text-field-focus-active-indicator-color: #005d6b;
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 93, 107, 0.87);
  --mdc-outlined-text-field-caret-color: #005d6b;
  --mdc-outlined-text-field-focus-outline-color: #005d6b;
  --mdc-outlined-text-field-focus-label-text-color: rgba(0, 93, 107, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(0, 93, 107, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: #f44336;
  --mdc-filled-text-field-focus-active-indicator-color: #f44336;
  --mdc-filled-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mdc-outlined-text-field-caret-color: #f44336;
  --mdc-outlined-text-field-focus-outline-color: #f44336;
  --mdc-outlined-text-field-focus-label-text-color: rgba(244, 67, 54, 0.87);
  --mat-form-field-focus-select-arrow-color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(0, 93, 107, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #424242;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(244, 67, 54, 0.87);
  --mat-select-invalid-arrow-color: rgba(244, 67, 54, 0.87);
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-container-shape-radius: 16px;
  --mdc-chip-with-avatar-avatar-shape-radius: 14px;
  --mdc-chip-with-avatar-avatar-size: 28px;
  --mdc-chip-with-icon-icon-size: 18px;
  --mdc-chip-outline-width: 0;
  --mdc-chip-outline-color: transparent;
  --mdc-chip-disabled-outline-color: transparent;
  --mdc-chip-focus-outline-color: transparent;
  --mdc-chip-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-avatar-disabled-avatar-opacity: 1;
  --mdc-chip-flat-selected-outline-width: 0;
  --mdc-chip-selected-hover-state-layer-opacity: 0.04;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-opacity: 1;
  --mdc-chip-with-icon-disabled-icon-opacity: 1;
  --mat-chip-disabled-container-opacity: 0.4;
  --mat-chip-trailing-action-opacity: 0.54;
  --mat-chip-trailing-action-focus-opacity: 1;
  --mat-chip-trailing-action-state-layer-color: transparent;
  --mat-chip-selected-trailing-action-state-layer-color: transparent;
  --mat-chip-trailing-action-hover-state-layer-opacity: 0;
  --mat-chip-trailing-action-focus-state-layer-opacity: 0;
}
.dark-theme .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #595959;
  --mdc-chip-elevated-selected-container-color: #595959;
  --mdc-chip-elevated-disabled-container-color: #595959;
  --mdc-chip-flat-disabled-selected-container-color: #595959;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-selected-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
  --mat-chip-selected-disabled-trailing-icon-color: #fafafa;
  --mat-chip-selected-trailing-icon-color: #fafafa;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #00aa6b;
  --mdc-chip-elevated-selected-container-color: #00aa6b;
  --mdc-chip-elevated-disabled-container-color: #00aa6b;
  --mdc-chip-flat-disabled-selected-container-color: #00aa6b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #005d6b;
  --mdc-chip-elevated-selected-container-color: #005d6b;
  --mdc-chip-elevated-disabled-container-color: #005d6b;
  --mdc-chip-flat-disabled-selected-container-color: #005d6b;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.dark-theme .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .dark-theme .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #f44336;
  --mdc-chip-elevated-selected-container-color: #f44336;
  --mdc-chip-elevated-disabled-container-color: #f44336;
  --mdc-chip-flat-disabled-selected-container-color: #f44336;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-hover-state-layer-color: white;
  --mdc-chip-selected-hover-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-selected-focus-state-layer-color: white;
  --mdc-chip-selected-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-selected-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
  --mat-chip-selected-disabled-trailing-icon-color: white;
  --mat-chip-selected-trailing-icon-color: white;
}
.dark-theme .mat-mdc-slide-toggle {
  --mat-switch-disabled-selected-handle-opacity: 0.38;
  --mat-switch-disabled-unselected-handle-opacity: 0.38;
  --mat-switch-unselected-handle-size: 20px;
  --mat-switch-selected-handle-size: 20px;
  --mat-switch-pressed-handle-size: 20px;
  --mat-switch-with-icon-handle-size: 20px;
  --mat-switch-selected-handle-horizontal-margin: 0;
  --mat-switch-selected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-selected-pressed-handle-horizontal-margin: 0;
  --mat-switch-unselected-handle-horizontal-margin: 0;
  --mat-switch-unselected-with-icon-handle-horizontal-margin: 0;
  --mat-switch-unselected-pressed-handle-horizontal-margin: 0;
  --mat-switch-visible-track-opacity: 1;
  --mat-switch-hidden-track-opacity: 1;
  --mat-switch-visible-track-transition: transform 75ms 0ms cubic-bezier(0, 0, 0.2, 1);
  --mat-switch-hidden-track-transition: transform 75ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  --mat-switch-track-outline-width: 1px;
  --mat-switch-track-outline-color: transparent;
  --mat-switch-selected-track-outline-width: 1px;
  --mat-switch-selected-track-outline-color: transparent;
  --mat-switch-disabled-unselected-track-outline-width: 1px;
  --mat-switch-disabled-unselected-track-outline-color: transparent;
}
.dark-theme .mat-mdc-slide-toggle {
  --mat-switch-label-text-color: white;
}
.dark-theme .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: #4d8e97;
  --mdc-switch-selected-handle-color: #4d8e97;
  --mdc-switch-selected-hover-state-layer-color: #4d8e97;
  --mdc-switch-selected-pressed-state-layer-color: #4d8e97;
  --mdc-switch-selected-focus-handle-color: #80aeb5;
  --mdc-switch-selected-hover-handle-color: #80aeb5;
  --mdc-switch-selected-pressed-handle-color: #80aeb5;
  --mdc-switch-selected-focus-track-color: #005563;
  --mdc-switch-selected-hover-track-color: #005563;
  --mdc-switch-selected-pressed-track-color: #005563;
  --mdc-switch-selected-track-color: #005563;
}
.dark-theme .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-state-layer-color: #e57373;
  --mdc-switch-selected-handle-color: #e57373;
  --mdc-switch-selected-hover-state-layer-color: #e57373;
  --mdc-switch-selected-pressed-state-layer-color: #e57373;
  --mdc-switch-selected-focus-handle-color: #ef9a9a;
  --mdc-switch-selected-hover-handle-color: #ef9a9a;
  --mdc-switch-selected-pressed-handle-color: #ef9a9a;
  --mdc-switch-selected-focus-track-color: #e53935;
  --mdc-switch-selected-hover-track-color: #e53935;
  --mdc-switch-selected-pressed-track-color: #e53935;
  --mdc-switch-selected-track-color: #e53935;
}
.dark-theme .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #00aa6b;
  --mdc-radio-selected-hover-icon-color: #00aa6b;
  --mdc-radio-selected-icon-color: #00aa6b;
  --mdc-radio-selected-pressed-icon-color: #00aa6b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #00aa6b;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.dark-theme .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #005d6b;
  --mdc-radio-selected-hover-icon-color: #005d6b;
  --mdc-radio-selected-icon-color: #005d6b;
  --mdc-radio-selected-pressed-icon-color: #005d6b;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #005d6b;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.dark-theme .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
  --mat-radio-ripple-color: white;
  --mat-radio-checked-ripple-color: #f44336;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
  --mat-radio-label-text-color: white;
}
.dark-theme .mat-accent {
  --mdc-slider-handle-color: #005d6b;
  --mdc-slider-focus-handle-color: #005d6b;
  --mdc-slider-hover-handle-color: #005d6b;
  --mdc-slider-active-track-color: #005d6b;
  --mdc-slider-inactive-track-color: #005d6b;
  --mdc-slider-with-tick-marks-inactive-container-color: #005d6b;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #005d6b;
  --mat-slider-hover-state-layer-color: rgba(0, 93, 107, 0.05);
  --mat-slider-focus-state-layer-color: rgba(0, 93, 107, 0.2);
}
.dark-theme .mat-warn {
  --mdc-slider-handle-color: #f44336;
  --mdc-slider-focus-handle-color: #f44336;
  --mdc-slider-hover-handle-color: #f44336;
  --mdc-slider-active-track-color: #f44336;
  --mdc-slider-inactive-track-color: #f44336;
  --mdc-slider-with-tick-marks-inactive-container-color: #f44336;
  --mdc-slider-with-tick-marks-active-container-color: white;
  --mat-slider-ripple-color: #f44336;
  --mat-slider-hover-state-layer-color: rgba(244, 67, 54, 0.05);
  --mat-slider-focus-state-layer-color: rgba(244, 67, 54, 0.2);
}
.dark-theme .mdc-list-item__start,
.dark-theme .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #00aa6b;
  --mdc-radio-selected-hover-icon-color: #00aa6b;
  --mdc-radio-selected-icon-color: #00aa6b;
  --mdc-radio-selected-pressed-icon-color: #00aa6b;
}
.dark-theme .mat-accent .mdc-list-item__start,
.dark-theme .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #005d6b;
  --mdc-radio-selected-hover-icon-color: #005d6b;
  --mdc-radio-selected-icon-color: #005d6b;
  --mdc-radio-selected-pressed-icon-color: #005d6b;
}
.dark-theme .mat-warn .mdc-list-item__start,
.dark-theme .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: white;
  --mdc-radio-disabled-unselected-icon-color: white;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-focus-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #f44336;
  --mdc-radio-selected-hover-icon-color: #f44336;
  --mdc-radio-selected-icon-color: #f44336;
  --mdc-radio-selected-pressed-icon-color: #f44336;
}
.dark-theme .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #00aa6b;
  --mdc-checkbox-selected-hover-icon-color: #00aa6b;
  --mdc-checkbox-selected-icon-color: #00aa6b;
  --mdc-checkbox-selected-pressed-icon-color: #00aa6b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00aa6b;
  --mdc-checkbox-selected-hover-state-layer-color: #00aa6b;
  --mdc-checkbox-selected-pressed-state-layer-color: #00aa6b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #005d6b;
  --mdc-checkbox-selected-hover-icon-color: #005d6b;
  --mdc-checkbox-selected-icon-color: #005d6b;
  --mdc-checkbox-selected-pressed-icon-color: #005d6b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #005d6b;
  --mdc-checkbox-selected-hover-state-layer-color: #005d6b;
  --mdc-checkbox-selected-pressed-state-layer-color: #005d6b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__start,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text,
.dark-theme .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__start {
  color: #00aa6b;
}
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.dark-theme .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.dark-theme .mat-mdc-tab-group, .dark-theme .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #00aa6b;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #00aa6b;
  --mat-tab-header-active-ripple-color: #00aa6b;
  --mat-tab-header-inactive-ripple-color: #00aa6b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #00aa6b;
  --mat-tab-header-active-hover-label-text-color: #00aa6b;
  --mat-tab-header-active-focus-indicator-color: #00aa6b;
  --mat-tab-header-active-hover-indicator-color: #00aa6b;
}
.dark-theme .mat-mdc-tab-group.mat-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #005d6b;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #005d6b;
  --mat-tab-header-active-ripple-color: #005d6b;
  --mat-tab-header-inactive-ripple-color: #005d6b;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #005d6b;
  --mat-tab-header-active-hover-label-text-color: #005d6b;
  --mat-tab-header-active-focus-indicator-color: #005d6b;
  --mat-tab-header-active-hover-indicator-color: #005d6b;
}
.dark-theme .mat-mdc-tab-group.mat-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: #f44336;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: white;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #f44336;
  --mat-tab-header-active-ripple-color: #f44336;
  --mat-tab-header-inactive-ripple-color: #f44336;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #f44336;
  --mat-tab-header-active-hover-label-text-color: #f44336;
  --mat-tab-header-active-focus-indicator-color: #f44336;
  --mat-tab-header-active-hover-indicator-color: #f44336;
}
.dark-theme .mat-mdc-tab-group.mat-background-primary, .dark-theme .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #00aa6b;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-mdc-tab-group.mat-background-accent, .dark-theme .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #005d6b;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-mdc-tab-group.mat-background-warn, .dark-theme .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: #f44336;
  --mat-tab-header-with-background-foreground-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #00aa6b;
  --mdc-checkbox-selected-hover-icon-color: #00aa6b;
  --mdc-checkbox-selected-icon-color: #00aa6b;
  --mdc-checkbox-selected-pressed-icon-color: #00aa6b;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #00aa6b;
  --mdc-checkbox-selected-hover-state-layer-color: #00aa6b;
  --mdc-checkbox-selected-pressed-state-layer-color: #00aa6b;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: white;
  --mdc-checkbox-selected-focus-icon-color: #f44336;
  --mdc-checkbox-selected-hover-icon-color: #f44336;
  --mdc-checkbox-selected-icon-color: #f44336;
  --mdc-checkbox-selected-pressed-icon-color: #f44336;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #f44336;
  --mdc-checkbox-selected-hover-state-layer-color: #f44336;
  --mdc-checkbox-selected-pressed-state-layer-color: #f44336;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.dark-theme .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #00aa6b;
  --mat-text-button-state-layer-color: #00aa6b;
  --mat-text-button-ripple-color: rgba(0, 170, 107, 0.1);
}
.dark-theme .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #005d6b;
  --mat-text-button-state-layer-color: #005d6b;
  --mat-text-button-ripple-color: rgba(0, 93, 107, 0.1);
}
.dark-theme .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: #f44336;
  --mat-text-button-state-layer-color: #f44336;
  --mat-text-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.dark-theme .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #00aa6b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #005d6b;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: #f44336;
  --mdc-filled-button-label-text-color: white;
  --mat-filled-button-state-layer-color: white;
  --mat-filled-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #00aa6b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #005d6b;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: #f44336;
  --mdc-protected-button-label-text-color: white;
  --mat-protected-button-state-layer-color: white;
  --mat-protected-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #00aa6b;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #00aa6b;
  --mat-outlined-button-ripple-color: rgba(0, 170, 107, 0.1);
}
.dark-theme .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #005d6b;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #005d6b;
  --mat-outlined-button-ripple-color: rgba(0, 93, 107, 0.1);
}
.dark-theme .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: #f44336;
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mat-outlined-button-state-layer-color: #f44336;
  --mat-outlined-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #00aa6b;
  --mat-icon-button-state-layer-color: #00aa6b;
  --mat-icon-button-ripple-color: rgba(0, 170, 107, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #005d6b;
  --mat-icon-button-state-layer-color: #005d6b;
  --mat-icon-button-ripple-color: rgba(0, 93, 107, 0.1);
}
.dark-theme .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: #f44336;
  --mat-icon-button-state-layer-color: #f44336;
  --mat-icon-button-ripple-color: rgba(244, 67, 54, 0.1);
}
.dark-theme .mat-mdc-fab.mat-primary {
  --mdc-fab-container-color: #00aa6b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-fab.mat-accent {
  --mdc-fab-container-color: #005d6b;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-fab.mat-warn {
  --mdc-fab-container-color: #f44336;
  --mat-fab-foreground-color: white;
  --mat-fab-state-layer-color: white;
  --mat-fab-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-small-container-color: #00aa6b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-small-container-color: #005d6b;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-small-container-color: #f44336;
  --mat-fab-small-foreground-color: white;
  --mat-fab-small-state-layer-color: white;
  --mat-fab-small-ripple-color: rgba(255, 255, 255, 0.1);
}
.dark-theme .mat-accent {
  --mdc-circular-progress-active-indicator-color: #005d6b;
}
.dark-theme .mat-warn {
  --mdc-circular-progress-active-indicator-color: #f44336;
}
.dark-theme .mat-badge-accent {
  --mat-badge-background-color: #005d6b;
  --mat-badge-text-color: white;
}
.dark-theme .mat-badge-warn {
  --mat-badge-background-color: #f44336;
  --mat-badge-text-color: white;
}
.dark-theme .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #005d6b;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(0, 93, 107, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(0, 93, 107, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(0, 93, 107, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(0, 93, 107, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark-theme .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #f44336;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(244, 67, 54, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(244, 67, 54, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(244, 67, 54, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.dark-theme .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #005d6b;
}
.dark-theme .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: #f44336;
}
.dark-theme .mat-icon.mat-primary {
  --mat-icon-color: #00aa6b;
}
.dark-theme .mat-icon.mat-accent {
  --mat-icon-color: #005d6b;
}
.dark-theme .mat-icon.mat-warn {
  --mat-icon-color: #f44336;
}
.dark-theme .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #005d6b;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #005d6b;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #005d6b;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.dark-theme .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #f44336;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #f44336;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #f44336;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.dark-theme .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #00aa6b;
  --mat-toolbar-container-text-color: white;
}
.dark-theme .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #005d6b;
  --mat-toolbar-container-text-color: white;
}
.dark-theme .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: #f44336;
  --mat-toolbar-container-text-color: white;
}
.dark-theme .event-week-item {
  border-color: #00aa6b !important;
}
.dark-theme #login-page h1 {
  color: white;
}
.dark-theme .page__head {
  background: #00aa6b;
}
.dark-theme .page__actions {
  background: #424242;
}
.dark-theme .primary-hover:hover {
  background-color: rgba(0, 170, 107, 0.1) !important;
}
.dark-theme .primary-bg {
  background-color: #00aa6b;
}
.dark-theme .primary-bg-light {
  background-color: rgba(0, 170, 107, 0.1);
}
.dark-theme .accent-bg {
  background-color: #005d6b;
}
.dark-theme .accent-bg-light {
  background-color: rgba(0, 93, 107, 0.1);
}
.dark-theme .primary-fg {
  color: white;
}
.dark-theme .primary-color {
  color: #00aa6b !important;
}
.dark-theme .accent-fg {
  color: white;
}
.dark-theme .accent-color {
  color: #005d6b !important;
}
.dark-theme .light-hint-text {
  color: rgba(255, 255, 255, 0.5);
}
.dark-theme .lighter-text {
  color: #a8a8a8;
}
.dark-theme .highlighted-textbox {
  color: #c4c4c4;
  background-color: #4b4b4b;
  border-color: #3a3a3a;
  border-width: 1px;
  border-style: solid;
  padding: 10px;
  border-radius: 10px;
}
.dark-theme .highlighted-textbox > *:first-child {
  margin: 0;
}
.dark-theme .initials-circle {
  background: #00aa6b;
  color: white;
}
.dark-theme .initials-circle.accent {
  background: #005d6b;
  color: white;
}
.dark-theme .initials-circle.warn {
  background: #f44336;
  color: white;
}
.dark-theme blockquote {
  border-color: #00aa6b;
}
.dark-theme .cdk-drag-preview {
  background: #424242;
  color: white;
}
.dark-theme .mat-toolbar {
  background: #212121 !important;
  color: white;
}

button.active {
  color: #005d6b !important;
}

button.pulse {
  box-shadow: 0 0 0 #005d6b;
  animation: pulse 2s infinite;
}
button.pulse:hover {
  animation: none;
}

.mat-mdc-nav-list .mat-mdc-list-item .lnr {
  margin-right: 10px;
}
.mat-mdc-nav-list .mat-mdc-list-item .mat-mdc-list-item-unscoped-content {
  height: 100%;
  width: 100%;
}
.mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content {
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
}
.mat-mdc-nav-list .mat-mdc-list-item.active {
  background-color: rgba(0, 170, 107, 0.2);
}
.mat-mdc-nav-list .mat-mdc-list-item.active:hover, .mat-mdc-nav-list .mat-mdc-list-item.active:focus {
  background-color: rgba(0, 170, 107, 0.3) !important;
}
.mat-mdc-nav-list .mat-mdc-list-item:hover, .mat-mdc-nav-list .mat-mdc-list-item:focus {
  background-color: rgba(0, 170, 107, 0.1) !important;
}

.mat-table.app-background {
  background-color: #fafafa !important;
}

tremaze-expanding-icon-text-input {
  color: white;
}
tremaze-expanding-icon-text-input input {
  background-color: rgba(0, 93, 107, 0.3);
  color: black;
}
tremaze-expanding-icon-text-input span {
  background-color: #005d6b;
}

.badge {
  background-color: #005d6b;
  color: white;
}
.badge--primary {
  background-color: #00aa6b;
  color: white;
}
.badge--grey {
  background-color: #f1f1f1;
  color: #333;
}

.hint--primary {
  background-color: rgba(0, 170, 107, 0.075);
  border-color: #00aa6b !important;
}
.hint--primary > p {
  border-color: #00aa6b !important;
}
.hint--accent {
  background-color: rgba(0, 93, 107, 0.075);
  border-color: #005d6b !important;
}
.hint--accent > p {
  border-color: #005d6b !important;
}
.hint--warn {
  background-color: rgba(244, 67, 54, 0.075);
  border-color: #f44336 !important;
}
.hint--warn > p {
  border-color: #f44336 !important;
}

.mat-mdc-raised-button:not(:disabled).mat-primary,
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-mini-fab.mat-primary,
.mat-mdc-fab.mat-primary {
  color: #ffffff !important;
}
.mat-mdc-raised-button:not(:disabled).mat-accent,
.mat-mdc-unelevated-button.mat-accent,
.mat-mdc-raised-button.mat-accent,
.mat-mdc-mini-fab.mat-accent,
.mat-mdc-fab.mat-accent {
  color: #ffffff !important;
}

.mdc-button {
  border-radius: 12px !important;
  font-weight: bold;
}
.mdc-button--raised {
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 12px !important;
}

.mat-mdc-checkbox.mat-accent svg {
  color: #ffffff !important;
}
.mat-mdc-checkbox.mat-primary svg {
  color: #ffffff !important;
}

.dark-theme button.active {
  color: #005d6b !important;
}
.dark-theme button.pulse {
  box-shadow: 0 0 0 #005d6b;
  animation: pulse 2s infinite;
}
.dark-theme button.pulse:hover {
  animation: none;
}
.dark-theme .mat-mdc-nav-list .mat-mdc-list-item .lnr {
  margin-right: 10px;
}
.dark-theme .mat-mdc-nav-list .mat-mdc-list-item .mat-mdc-list-item-unscoped-content {
  height: 100%;
  width: 100%;
}
.dark-theme .mat-mdc-nav-list .mat-mdc-list-item .mdc-list-item__content {
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
}
.dark-theme .mat-mdc-nav-list .mat-mdc-list-item.active {
  background-color: rgba(0, 170, 107, 0.2);
}
.dark-theme .mat-mdc-nav-list .mat-mdc-list-item.active:hover, .dark-theme .mat-mdc-nav-list .mat-mdc-list-item.active:focus {
  background-color: rgba(0, 170, 107, 0.3) !important;
}
.dark-theme .mat-mdc-nav-list .mat-mdc-list-item:hover, .dark-theme .mat-mdc-nav-list .mat-mdc-list-item:focus {
  background-color: rgba(0, 170, 107, 0.1) !important;
}
.dark-theme .mat-table.app-background {
  background-color: #303030 !important;
}
.dark-theme tremaze-expanding-icon-text-input {
  color: white;
}
.dark-theme tremaze-expanding-icon-text-input input {
  background-color: rgba(0, 93, 107, 0.3);
  color: black;
}
.dark-theme tremaze-expanding-icon-text-input span {
  background-color: #005d6b;
}
.dark-theme .badge {
  background-color: #005d6b;
  color: white;
}
.dark-theme .badge--primary {
  background-color: #00aa6b;
  color: white;
}
.dark-theme .badge--grey {
  background-color: #333;
  color: #f1f1f1;
}
.dark-theme .hint--primary {
  background-color: rgba(0, 170, 107, 0.075);
  border-color: #00aa6b !important;
}
.dark-theme .hint--primary > p {
  border-color: #00aa6b !important;
}
.dark-theme .hint--accent {
  background-color: rgba(0, 93, 107, 0.075);
  border-color: #005d6b !important;
}
.dark-theme .hint--accent > p {
  border-color: #005d6b !important;
}
.dark-theme .hint--warn {
  background-color: rgba(244, 67, 54, 0.075);
  border-color: #f44336 !important;
}
.dark-theme .hint--warn > p {
  border-color: #f44336 !important;
}
.dark-theme .mat-mdc-raised-button:not(:disabled).mat-primary,
.dark-theme .mat-mdc-unelevated-button.mat-primary,
.dark-theme .mat-mdc-raised-button.mat-primary,
.dark-theme .mat-mdc-mini-fab.mat-primary,
.dark-theme .mat-mdc-fab.mat-primary {
  color: #ffffff !important;
}
.dark-theme .mat-mdc-raised-button:not(:disabled).mat-accent,
.dark-theme .mat-mdc-unelevated-button.mat-accent,
.dark-theme .mat-mdc-raised-button.mat-accent,
.dark-theme .mat-mdc-mini-fab.mat-accent,
.dark-theme .mat-mdc-fab.mat-accent {
  color: #ffffff !important;
}
.dark-theme .mdc-button {
  border-radius: 12px !important;
  font-weight: bold;
}
.dark-theme .mdc-button--raised {
  box-shadow: rgba(0, 0, 0, 0.05) 0 0 12px !important;
}
.dark-theme .mat-mdc-checkbox.mat-accent svg {
  color: #ffffff !important;
}
.dark-theme .mat-mdc-checkbox.mat-primary svg {
  color: #ffffff !important;
}

a {
  color: #26b781;
  transition: color 0.2s ease-in-out;
}
a:hover {
  color: #00a363;
}
.default-table thead {
  background-color: white;
  border-bottom: 20px solid transparent;
}
tbody > tr:not(.default-table__nostyle):hover {
  background-color: rgba(224, 245, 237, 0.3) !important;
}
.default-table:not(.even) tbody tr:nth-child(odd):not(.default-table__nostyle), .default-table.even tbody tr:nth-child(even):not(.default-table__nostyle) {
  background-color: rgba(224, 245, 237, 0.5);
}
.default-table:not(.even) tbody tr:nth-child(odd):not(.default-table__nostyle):hover, .default-table.even tbody tr:nth-child(even):not(.default-table__nostyle):hover {
  background-color: rgba(224, 245, 237, 0.8) !important;
}

.innerHTML {
  color: rgba(0, 0, 0, 0.87);
}

.dark-theme a {
  color: #26b781;
  transition: color 0.2s ease-in-out;
}
.dark-theme a:hover {
  color: #00a363;
}
.dark-theme .default-table thead {
  background-color: #424242;
  border-bottom: 20px solid transparent;
}
tbody > tr:not(.dark-theme .default-table__nostyle):hover {
  background-color: rgba(224, 245, 237, 0.3) !important;
}
.dark-theme .default-table:not(.even) tbody tr:nth-child(odd):not(.dark-theme .default-table__nostyle), .dark-theme .default-table.even tbody tr:nth-child(even):not(.dark-theme .default-table__nostyle) {
  background-color: rgba(224, 245, 237, 0.5);
}
.dark-theme .default-table:not(.even) tbody tr:nth-child(odd):not(.dark-theme .default-table__nostyle):hover, .dark-theme .default-table.even tbody tr:nth-child(even):not(.dark-theme .default-table__nostyle):hover {
  background-color: rgba(224, 245, 237, 0.8) !important;
}
.dark-theme .innerHTML {
  color: white;
}

.tremaze-card {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.tremaze-card--floating {
  box-shadow: rgba(128, 128, 128, 0.53) 0 0 25px;
}
.tremaze-card--outlined {
  border: 2px solid #ececec;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05), 0 18px 18px 0 rgba(0, 0, 0, 0.05), 0 40px 24px 0 rgba(0, 0, 0, 0.03), 0 70px 28px 0 rgba(0, 0, 0, 0), 0 110px 31px 0 rgba(0, 0, 0, 0);
}

.tremaze-card__Title {
  font-family: var(--mat-card-title-text-font);
  line-height: var(--mat-card-title-text-line-height);
  font-size: var(--mat-card-title-text-size);
  letter-spacing: var(--mat-card-title-text-tracking);
  font-weight: var(--mat-card-title-text-weight);
}
.tremaze-card__Subtitle {
  color: var(--mat-card-subtitle-text-color);
  font-family: var(--mat-card-subtitle-text-font);
  line-height: var(--mat-card-subtitle-text-line-height);
  font-size: var(--mat-card-subtitle-text-size);
  letter-spacing: var(--mat-card-subtitle-text-tracking);
  font-weight: var(--mat-card-subtitle-text-weight);
}

.tremaze-chip {
  background-color: #f0f0f0;
  color: black;
}

.tremaze-expandable-chip-collection {
  background-color: #f0f0f0;
}
.tremaze-expandable-chip-collection .tremaze-chip {
  background-color: #fff;
  color: black;
}
.tremaze-expandable-chip-collection__Hidden-Chips-Preview {
  background-color: #00aa6b;
  color: white;
}

.icon-text-button {
  transition: background-color 0.2s, color 0.2s;
}
.icon-text-button--primary {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.icon-text-button--primary .icon-text-button__Icon {
  color: #00aa6b;
}
.icon-text-button--primary.icon-text-button--active:not(.icon-text-button--primary.icon-text-button--disabled) {
  background-color: #00aa6b;
  color: white;
}
.icon-text-button--primary.icon-text-button--active:not(.icon-text-button--primary.icon-text-button--disabled) .icon-text-button__Icon {
  color: white;
}
.icon-text-button--primary.icon-text-button--disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.icon-text-button--primary.icon-text-button--disabled .icon-text-button__Icon {
  color: rgba(0, 0, 0, 0.26);
}
.icon-text-button--primary:hover:not(.icon-text-button--primary.icon-text-button--active):not(.icon-text-button--primary.icon-text-button--disabled) {
  background-color: #b3e6d3;
  color: black;
}
.icon-text-button--primary:hover:not(.icon-text-button--primary.icon-text-button--active):not(.icon-text-button--primary.icon-text-button--disabled) .icon-text-button__Icon {
  color: black;
}

.icon-text-button {
  transition: background-color 0.2s, color 0.2s;
}
.icon-text-button--accent {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.icon-text-button--accent .icon-text-button__Icon {
  color: #005d6b;
}
.icon-text-button--accent.icon-text-button--active:not(.icon-text-button--accent.icon-text-button--disabled) {
  background-color: #005d6b;
  color: white;
}
.icon-text-button--accent.icon-text-button--active:not(.icon-text-button--accent.icon-text-button--disabled) .icon-text-button__Icon {
  color: white;
}
.icon-text-button--accent.icon-text-button--disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.icon-text-button--accent.icon-text-button--disabled .icon-text-button__Icon {
  color: rgba(0, 0, 0, 0.26);
}
.icon-text-button--accent:hover:not(.icon-text-button--accent.icon-text-button--active):not(.icon-text-button--accent.icon-text-button--disabled) {
  background-color: #b3ced3;
  color: black;
}
.icon-text-button--accent:hover:not(.icon-text-button--accent.icon-text-button--active):not(.icon-text-button--accent.icon-text-button--disabled) .icon-text-button__Icon {
  color: black;
}

.icon-text-button {
  transition: background-color 0.2s, color 0.2s;
}
.icon-text-button--warn {
  border-color: rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.icon-text-button--warn .icon-text-button__Icon {
  color: #f44336;
}
.icon-text-button--warn.icon-text-button--active:not(.icon-text-button--warn.icon-text-button--disabled) {
  background-color: #f44336;
  color: white;
}
.icon-text-button--warn.icon-text-button--active:not(.icon-text-button--warn.icon-text-button--disabled) .icon-text-button__Icon {
  color: white;
}
.icon-text-button--warn.icon-text-button--disabled {
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
}
.icon-text-button--warn.icon-text-button--disabled .icon-text-button__Icon {
  color: rgba(0, 0, 0, 0.26);
}
.icon-text-button--warn:hover:not(.icon-text-button--warn.icon-text-button--active):not(.icon-text-button--warn.icon-text-button--disabled) {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.87);
}
.icon-text-button--warn:hover:not(.icon-text-button--warn.icon-text-button--active):not(.icon-text-button--warn.icon-text-button--disabled) .icon-text-button__Icon {
  color: rgba(0, 0, 0, 0.87);
}

.select-zone-selection-box {
  background: rgba(0, 0, 0, 0.15);
  border: 1px solid black;
}

.drag-zone--Dragging .drop-zone:not(.drop-zone--Disabled) {
  outline: 2px dashed #26b781;
}
.drag-zone--Dragging .drop-zone:not(.drop-zone--Disabled):hover {
  background: #80d5b5 !important;
  color: black !important;
}

.file-drop-in-drop-zone--DraggingOver:not(.folder-view-folders-and-files__Body) {
  background: #80d5b5 !important;
  color: black !important;
}
.file-drop-in-drop-zone--Dragging:not(.folder-view-folders-and-files__Files-Wrapper):not(.file-drop-in-drop-zone.file-drop-in-drop-zone--Disabled) {
  outline: 2px dashed #26b781;
}
.file-drop-in-drop-zone--Dragging:not(.file-drop-in-drop-zone.file-drop-in-drop-zone--Disabled) .folder-view-folders-and-files__Files-Wrapper__Drop-Zone {
  outline: 2px dashed #26b781;
}

.folder-view-actions-row {
  background: transparent;
}

.folder-view > div,
.folder-view-list-layout-list {
  background: white;
}

.folder-view-grid-layout-item {
  background: #f1f1f1;
  color: #000000;
  transition: background 0.1s;
  overflow: visible;
}
.folder-view-grid-layout-item:hover:not(.folder-view-grid-layout-item--Disabled):not(.folder-view-grid-layout-item--Selected) {
  background: #e1e1e1;
}
.folder-view-grid-layout-item--Selected {
  background: #b3e6d3;
  color: black;
}
.folder-view-grid-layout-item--Selected:hover {
  background: #80d5b5;
}

.dark-theme .tremaze-card {
  background-color: #424242;
  color: white;
}
.dark-theme .tremaze-card--floating {
  box-shadow: rgba(128, 128, 128, 0.53) 0 0 25px;
}
.dark-theme .tremaze-card--outlined {
  border: 2px solid #555555;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05), 0 18px 18px 0 rgba(0, 0, 0, 0.05), 0 40px 24px 0 rgba(0, 0, 0, 0.03), 0 70px 28px 0 rgba(0, 0, 0, 0), 0 110px 31px 0 rgba(0, 0, 0, 0);
}
.dark-theme .tremaze-card__Title {
  font-family: var(--mat-card-title-text-font);
  line-height: var(--mat-card-title-text-line-height);
  font-size: var(--mat-card-title-text-size);
  letter-spacing: var(--mat-card-title-text-tracking);
  font-weight: var(--mat-card-title-text-weight);
}
.dark-theme .tremaze-card__Subtitle {
  color: var(--mat-card-subtitle-text-color);
  font-family: var(--mat-card-subtitle-text-font);
  line-height: var(--mat-card-subtitle-text-line-height);
  font-size: var(--mat-card-subtitle-text-size);
  letter-spacing: var(--mat-card-subtitle-text-tracking);
  font-weight: var(--mat-card-subtitle-text-weight);
}
.dark-theme .tremaze-chip {
  background-color: #555555;
  color: white;
}
.dark-theme .tremaze-expandable-chip-collection {
  background-color: #555555;
}
.dark-theme .tremaze-expandable-chip-collection .tremaze-chip {
  background-color: #333;
  color: white;
}
.dark-theme .tremaze-expandable-chip-collection__Hidden-Chips-Preview {
  background-color: #00aa6b;
  color: white;
}
.dark-theme .icon-text-button {
  transition: background-color 0.2s, color 0.2s;
}
.dark-theme .icon-text-button--primary {
  border-color: rgba(255, 255, 255, 0.12);
  background-color: #424242;
  color: white;
}
.dark-theme .icon-text-button--primary .icon-text-button__Icon {
  color: #00aa6b;
}
.dark-theme .icon-text-button--primary.icon-text-button--active:not(.dark-theme .icon-text-button--primary.icon-text-button--disabled) {
  background-color: #00aa6b;
  color: white;
}
.dark-theme .icon-text-button--primary.icon-text-button--active:not(.dark-theme .icon-text-button--primary.icon-text-button--disabled) .icon-text-button__Icon {
  color: white;
}
.dark-theme .icon-text-button--primary.icon-text-button--disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .icon-text-button--primary.icon-text-button--disabled .icon-text-button__Icon {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .icon-text-button--primary:hover:not(.dark-theme .icon-text-button--primary.icon-text-button--active):not(.dark-theme .icon-text-button--primary.icon-text-button--disabled) {
  background-color: #b3e6d3;
  color: black;
}
.dark-theme .icon-text-button--primary:hover:not(.dark-theme .icon-text-button--primary.icon-text-button--active):not(.dark-theme .icon-text-button--primary.icon-text-button--disabled) .icon-text-button__Icon {
  color: black;
}
.dark-theme .icon-text-button {
  transition: background-color 0.2s, color 0.2s;
}
.dark-theme .icon-text-button--accent {
  border-color: rgba(255, 255, 255, 0.12);
  background-color: #424242;
  color: white;
}
.dark-theme .icon-text-button--accent .icon-text-button__Icon {
  color: #005d6b;
}
.dark-theme .icon-text-button--accent.icon-text-button--active:not(.dark-theme .icon-text-button--accent.icon-text-button--disabled) {
  background-color: #005d6b;
  color: white;
}
.dark-theme .icon-text-button--accent.icon-text-button--active:not(.dark-theme .icon-text-button--accent.icon-text-button--disabled) .icon-text-button__Icon {
  color: white;
}
.dark-theme .icon-text-button--accent.icon-text-button--disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .icon-text-button--accent.icon-text-button--disabled .icon-text-button__Icon {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .icon-text-button--accent:hover:not(.dark-theme .icon-text-button--accent.icon-text-button--active):not(.dark-theme .icon-text-button--accent.icon-text-button--disabled) {
  background-color: #b3ced3;
  color: black;
}
.dark-theme .icon-text-button--accent:hover:not(.dark-theme .icon-text-button--accent.icon-text-button--active):not(.dark-theme .icon-text-button--accent.icon-text-button--disabled) .icon-text-button__Icon {
  color: black;
}
.dark-theme .icon-text-button {
  transition: background-color 0.2s, color 0.2s;
}
.dark-theme .icon-text-button--warn {
  border-color: rgba(255, 255, 255, 0.12);
  background-color: #424242;
  color: white;
}
.dark-theme .icon-text-button--warn .icon-text-button__Icon {
  color: #f44336;
}
.dark-theme .icon-text-button--warn.icon-text-button--active:not(.dark-theme .icon-text-button--warn.icon-text-button--disabled) {
  background-color: #f44336;
  color: white;
}
.dark-theme .icon-text-button--warn.icon-text-button--active:not(.dark-theme .icon-text-button--warn.icon-text-button--disabled) .icon-text-button__Icon {
  color: white;
}
.dark-theme .icon-text-button--warn.icon-text-button--disabled {
  background-color: rgba(255, 255, 255, 0.12);
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .icon-text-button--warn.icon-text-button--disabled .icon-text-button__Icon {
  color: rgba(255, 255, 255, 0.3);
}
.dark-theme .icon-text-button--warn:hover:not(.dark-theme .icon-text-button--warn.icon-text-button--active):not(.dark-theme .icon-text-button--warn.icon-text-button--disabled) {
  background-color: #ffcdd2;
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .icon-text-button--warn:hover:not(.dark-theme .icon-text-button--warn.icon-text-button--active):not(.dark-theme .icon-text-button--warn.icon-text-button--disabled) .icon-text-button__Icon {
  color: rgba(0, 0, 0, 0.87);
}
.dark-theme .select-zone-selection-box {
  background: rgba(255, 255, 255, 0.15);
  border: 1px solid white;
}
.dark-theme .drag-zone--Dragging .drop-zone:not(.drop-zone--Disabled) {
  outline: 2px dashed #26b781;
}
.dark-theme .drag-zone--Dragging .drop-zone:not(.drop-zone--Disabled):hover {
  background: #4dc497 !important;
  color: black !important;
}
.dark-theme .file-drop-in-drop-zone--DraggingOver:not(.folder-view-folders-and-files__Body) {
  background: #4dc497 !important;
  color: black !important;
}
.dark-theme .file-drop-in-drop-zone--Dragging:not(.folder-view-folders-and-files__Files-Wrapper):not(.dark-theme .file-drop-in-drop-zone.file-drop-in-drop-zone--Disabled) {
  outline: 2px dashed #26b781;
}
.dark-theme .file-drop-in-drop-zone--Dragging:not(.dark-theme .file-drop-in-drop-zone.file-drop-in-drop-zone--Disabled) .folder-view-folders-and-files__Files-Wrapper__Drop-Zone {
  outline: 2px dashed #26b781;
}
.dark-theme .folder-view-actions-row {
  background: transparent;
}
.dark-theme .folder-view > div,
.dark-theme .folder-view-list-layout-list {
  background: #424242;
}
.dark-theme .folder-view-grid-layout-item {
  background: #2f2f2f;
  color: #ffffff;
  transition: background 0.1s;
  overflow: visible;
}
.dark-theme .folder-view-grid-layout-item:hover:not(.dark-theme .folder-view-grid-layout-item--Disabled):not(.dark-theme .folder-view-grid-layout-item--Selected) {
  background: #1a1a1a;
}
.dark-theme .folder-view-grid-layout-item--Selected {
  background: #00aa6b;
  color: white;
}
.dark-theme .folder-view-grid-layout-item--Selected:hover {
  background: #00a363;
}